import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import reduxThunk from 'redux-thunk'
import { Cookies } from 'react-cookie';
import { INICIAR_SESION } from './actions/types'
import routes from './routes'
import reducers from './reducers'
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify'

import './assets/css/dashboard.css'
import "react-notification-alert/dist/animate.css";
import 'sweetalert2/src/sweetalert2.scss'
import './assets/css/react-data-grid.css'
import './assets/css/tabs.css'
import './assets/css/tables.css'
import './assets/css/main.css'
import './assets/css/dNd.css'
import 'react-toastify/dist/ReactToastify.min.css'

import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const cookie = new Cookies();
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore)
const store = createStoreWithMiddleware(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
const history = createBrowserHistory()

const token = cookie.get('token')
const user = cookie.get('user')
const rol = cookie.get('rol')
const apiUrl = cookie.get('apiUrl')
const pageName = cookie.get('pageName')

if (token) {
    store.dispatch({ type: INICIAR_SESION, payload: { user, rol, apiUrl, pageName } })
}

window.root = window.document.getElementById('root')

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            {routes}
        </Router>
        <ToastContainer 
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
    </Provider>,
    window.root
)

serviceWorker.unregister();
