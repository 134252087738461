import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../actions/auth'
import { Cookies } from 'react-cookie';
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import { clearMessage } from '../actions/index'


//ui
import '../assets/css/fontawesome-free/css/all.css'
import '../assets/css/dashboard.css'

import Fondo from '../assets/images/Fondo.png'
import None from '../assets/images/none.jpg'
import Logo1 from '../assets/images/logo1.jpg'
import Logo2 from '../assets/images/logo2.jpg'
import Logo3 from '../assets/images/logo3.jpg'

const cookie = new Cookies();


class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            apiUrl: '',
            signin: false,
            logo: None,
            border: "",
            pageName: "",
            showPwd: false
        }
    }

    componentWillMount() {
        this.props.clearMessage()
        const token = cookie.get('token')
        if (token) {
            this.props.history.push('/')
        }
    }

    onSignIn = (e) => {
        e.preventDefault()
        if (this.state.apiUrl) {
            this.setState({ signin: true })
            const data = { username: this.state.username, password: this.state.password, apiUrl: this.state.apiUrl, pageName: this.state.pageName }
            this.props.signIn(data, () => { this.setState({ signin: false }) })
        } else {
            toast.warn("Recuerde completar toda la información del formulario")
        }
    }

    onChangeImage(apiUrl) {
        switch (Number(apiUrl)) {
            case 1:
                this.setState({ logo: Logo1, border: "bg-green", pageName: "CrediFenix I", apiUrl: "http://credidb.creditoscali.com/api" })
                break;
            case 2:
                this.setState({ logo: Logo2, border: "bg-orange", pageName: "CrediFenix II", apiUrl: "http://credidb.creditoscali.com/api" })
                break;
            case 3:
                this.setState({ logo: Logo3, border: "bg-blue", pageName: "PrestaLoto", apiUrl: "http://credidb.creditoscali.com/api" })
                break;
            case 4:
                this.setState({ logo: None, border: "bg-green", pageName: "Pruebas", apiUrl: "http://localhost:8000/api" })
                break;
            default:
                break;
        }
    }

    componentDidUpdate() {
        this.props.clearMessage()
    }

    render() {

        if (this.props.messages) {
            const { messages } = this.props
            switch (messages.type) {
                case 'danger':
                    toast.error(messages.message)
                    break
                case 'warning':
                    toast.warn(messages.message)
                    break
                default:
                    toast.info(messages.message)
            }
        }

        return (
            <div className="page" style={{ flex: '1', backgroundImage: `url(${Fondo})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                <div className="page-single">
                    <div className="container">
                        <div className="row">
                            <div className="col col-login mx-auto">
                                <form className="card" action="" onSubmit={this.onSignIn} >
                                    <div className={`card-status ${this.state.border}`}></div>
                                    <div className="card-body p-6">
                                        <div className="text-center card-title">¡Bienvenido de nuevo!</div>
                                        <div className="text-center card-title">ESTE ES UN ESPACIO DE PRUEBAS</div>
                                        <div className="text-center mb-2">
                                            <img src={this.state.logo} className="img-fluid" alt="Responsive image" />
                                        </div>
                                        <div className="form-group">
                                            {/* <select className="custom-select form-control" onChange={(e) => this.onChangeImage(e.target.value, e.target.selectedIndex)}> */}
                                            <TextField
                                                select
                                                label="Negocio"
                                                size="small" fullWidth
                                                onChange={(e) => this.onChangeImage(e.target.value, e)}
                                            >
                                                <option value="">Seleccione...</option>
                                                <option value="1" name="1" label="CrediFenix 1" > CrediFenix 1 </option>
                                                <option value="2" name="2" label="CrediFenix 2" > CrediFenix 2 </option>
                                                <option value="3" name="3" label="Prestaloto" > Prestaloto </option>
                                                <option value="4" name="4" label="Pruebas" > Pruebas </option>
                                            </TextField>
                                        </div>
                                        <div className="form-group">
                                            <TextField id="cuota" label="Usuario" size="small" fullWidth onChange={(e) => this.setState({ username: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <FormControl fullWidth >
                                                <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                                <Input
                                                    id="outlined-adornment-password"
                                                    type={this.state.showPwd ? 'text' : 'password'}
                                                    onChange={(e) => this.setState({ password: e.target.value })}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="Ver contraseña"
                                                                onClick={() => this.setState({ showPwd: !this.state.showPwd })}
                                                            >
                                                                {this.state.showPwd ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="form-footer">
                                            <button className={`btn btn-block text-white ${this.state.border}`} type="submit" disabled={this.state.signin}>
                                                {
                                                    (this.state.signin) ? <span><FontAwesome name="cog" spin />  Iniciando sesión ...</span> : 'Iniciar sesión'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        messages: state.messages.get('message'),
        isAuthenticated: state.auth.isAuthenticated
    }
}

function mapDispatchToProps(dispatch) {
    return {
        signIn: (credentials, callback) => dispatch(signIn(credentials, callback)),
        clearMessage: () => dispatch(clearMessage())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)