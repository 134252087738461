import React, { Component } from 'react';
import { connect } from 'react-redux'
import { CLIENTE } from '../../actions/index'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

class Loading extends Component {
    render() {
        const { modal } = this.props;
        if (!modal) {
            return null
        }

        return (
            <Backdrop open className="loading-main" style={{ zIndex: 10000 }}>
                <CircularProgress color="inherit" className={`${CLIENTE(1)}`} />
                <span className={`textLoading ${CLIENTE(1)}`}>UN MOMENTO POR FAVOR...</span>
            </Backdrop>
        )
    }
}

function mapStateToProps(state) {
    return {
        modal: state.common.get('waiting'),
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading)