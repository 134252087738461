import Swal from 'sweetalert2'
import {
    Cookies
} from 'react-cookie';

import * as types from './types'
import {
    signOutOn401
} from './auth'

const cookie = new Cookies();

export const API_URL = cookie.get('apiUrl');

export const CLIENT_ROOT_URL = window.CLIENT_ROOT_URL

export function clearMessage() {
    return (dispatch) => {
        dispatch({
            type: types.LIMPIAR_MENSAJES
        })
    }
}

export function CLIENTE(type = 0) {
    let cli = ""

    switch (type) {
        case 0:
            cli = "bg-";
            break;
        case 1:
            cli = "text-";
            break;
        case 2:
            cli = "margin-";
            break;
        default:
            break;
    }
    switch (cookie.get('pageName')) {
        case "CrediFenix I":
            cli += "green"
            break;
        case "CrediFenix II":
            cli += "orange"
            break;
        case "PrestaLoto":
            cli += "blue"
            break;
        default:
            cli += "blue"
            break;
    }
    return cli;
}

export function COLOR() {
    let cli = "";
    switch (cookie.get('pageName')) {
        case "CrediFenix I":
            cli += "#3fc337"
            break;
        case "CrediFenix II":
            cli += "#fd9644"
            break;
        case "PrestaLoto":
            cli += "#467fcf"
            break;
        default:
            cli += "#467fcf"
            break;
    }
    return cli;
}

export function messageHandler(dispatch, error) {
    let theMessage = {
        type: 'warning',
        message: ''
    }

    if (error.response) {
        switch (error.response.status) {
            case 400:
                Swal.fire('No está autorizado para proceder con esta solicitud. Por favor, inicie sesión y vuelva a intentarlo')
                // signOutOn401(dispatch);
            case 401:
                Swal.fire('No está autorizado para proceder con esta solicitud. Por favor, inicie sesión y vuelva a intentarlo')
                // signOutOn401(dispatch);
                break;
            case 422:
                if (error.response.data.errors) {
                    for (let property in error.response.data.errors) {
                        if (error.response.data.errors.hasOwnProperty(property)) {
                            for (let n = 0; n < error.response.data.errors[property].length; n++) {
                                dispatch({
                                    type: types.MOSTRAR_MENSAJE,
                                    payload: {
                                        type: 'warning',
                                        message: error.response.data.errors[property][n]
                                    }
                                })
                            }
                        }
                    }
                }
                break;
            case 423:
                console.log(error.response.data.Error)
                Swal.fire({
                    text: error.response.data.Error,
                    type: 'error',
                    title: 'Ooops... ¡Algo anda mal!'
                })
                break;
            case 424:
                theMessage.message = error.response.data.Error;
                dispatch({
                    type: types.MOSTRAR_MENSAJE,
                    payload: theMessage
                })
                break;
            default:
                Swal.fire(error.response.data.message)
                break;
        }
    } else {
        if (error.data) {
            theMessage.message = error.data.Message
        } else if (error.message) {
            theMessage.type = 'danger'
            theMessage.message = error.message
        } else if (error.success) {
            theMessage.type = 'success'
            theMessage.message = error.success
        } else if (error.warning) {
            theMessage.type = 'warning'
            theMessage.message = error.warning
        } else {
            theMessage.type = 'danger'
            theMessage.message = error
        }

        dispatch({
            type: types.MOSTRAR_MENSAJE,
            payload: theMessage
        })

    }

    if (error.status === 401) {
        dispatch({
            type: types.MOSTRAR_MENSAJE,
            payload: {
                type: 'danger',
                message: 'No esta autorizado. Por favor, inicie sesión y vuelva a intentarlo'
            }
        })
        signOutOn401(dispatch);
    }
}