// VARIABLES
export const API_URL = window.API_URL;

//Login
export const INICIAR_SESION = 'INICIAR_SESION'
export const CERRAR_SESION = 'CERRAR_SESION'

//Dashboard
export const GET_DASHBOARD = 'GET_DASHBOARD'

//Common
export const SHOW_MODAL = 'SHOW_MODAL'
export const SHOW_MODAL_CLIENTES = 'SHOW_MODAL_CLIENTES'
export const MOSTRAR_MENSAJE = 'MOSTRAR_MENSAJE'
export const LIMPIAR_MENSAJES = 'LIMPIAR_MENSAJES'
export const MOSTRAR_MESSAGE_BOX = 'MOSTRAR_MESSAGE_BOX'
export const MOSTRAR_MESSAGE_INFO_BOX = 'MOSTRAR_MESSAGE_INFO_BOX'
export const SET_LOADING = 'SET_LOADING'
export const TOOGLE_SIDEBAR = 'TOOGLE_SIDEBAR'
export const TOOGLE_SIDEBAR_RIGTH = 'TOOGLE_SIDEBAR_RIGTH'

//Usarios
export const GET_USERS = 'GET_USERS'
export const SELECCIONAR_USUARIO = 'SELECCIONAR_USUARIO'
export const NEW_USUARIO = 'NEW_USUARIO'
export const CHANGE_ATTR_USUARIO = 'CHANGE_ATTR_USUARIO'
export const CLEAN_USUARIO = 'CLEAN_USUARIO'
export const CHANGE_CHECK = 'CHANGE_CHECK'


//Roles
export const GET_ROLES_BY_ID = 'GET_ROLES_BY_ID'
export const CHANGE_PERMISSION = 'CHANGE_PERMISSION'
export const GET_ROLES_VIEW = 'GET_ROLES_VIEW'


//Clientes
export const GET_CLIENTES = 'GET_CLIENTES'
export const SELECCIONAR_CLIENTE = 'SELECCIONAR_CLIENTE'
export const SET_DETALLES_CLIENTES = 'SET_DETALLES_CLIENTES'
export const SELECCIONAR_CLIENTE_FIADOR = 'SELECCIONAR_CLIENTE_FIADOR'
export const SELECCIONAR_CLIENTE_TITULAR = 'SELECCIONAR_CLIENTE_TITULAR'
export const NEW_CLIENTE = 'NEW_CLIENTE'
export const NEW_REFERENCIA = 'NEW_REFERENCIA'
export const CHANGE_ATTR_CLIENTE = 'CHANGE_ATTR_CLIENTE'
export const CHANGE_ATTR_REFERENCIA = 'CHANGE_ATTR_REFERENCIA'
export const CLEAN_CLIENTE = 'CLEAN_CLIENTE'
export const EDIT_CLIENTE = 'EDIT_CLIENTE'
export const EDIT_REFERENCIA = 'EDIT_REFERENCIA'
export const ADD_REFERENCIA = 'ADD_REFERENCIA'
export const GET_LISTA_ROLES = 'GET_LISTA_ROLES'


//Parametros
export const GET_PARAMETROS = 'GET_PARAMETROS'
export const SELECCIONAR_PARAMETRO = 'SELECCIONAR_PARAMETRO'
export const NEW_PARAMETRO = 'NEW_PARAMETRO'
export const CHANGE_ATTR_LISTA_PARAMETRO = 'CHANGE_ATTR_LISTA_PARAMETRO'
export const CHANGE_ATTR_PARAMETRO = 'CHANGE_ATTR_PARAMETRO'
export const CLEAN_PARAMETRO = 'CLEAN_PARAMETRO'


// Rutas
export const GET_RUTAS = 'GET_RUTAS'
export const GET_CLIENTES_RUTA = 'GET_CLIENTES_RUTA'
export const SELECCIONAR_RUTA = 'SELECCIONAR_RUTA'
export const SELECCIONAR_DETALLE_RUTA = 'SELECCIONAR_DETALLE_RUTA'
export const CHANGE_ATTR_RUTA = 'CHANGE_ATTR_RUTA'
export const CHANGE_ATTR_RENOVACION = 'CHANGE_ATTR_RENOVACION'
export const CHANGE_ATTR_LISTA_RUTA = 'CHANGE_ATTR_LISTA_RUTA'
export const NEW_RUTA = 'NEW_RUTA'
export const SELECT_CLIENTE = 'SELECT_CLIENTE'
export const GET_LISTA_RUTAS = 'GET_LISTA_RUTAS'
export const GET_LISTA_PERIODOS = 'GET_LISTA_PERIODOS'
export const GET_DETALLES_RUTAS = 'GET_DETALLES_RUTAS'
export const GET_DETALLE_RENOVACION = 'GET_DETALLE_RENOVACION'
export const SELECCIONAR_DETALLE_RENOVACION = 'SELECCIONAR_DETALLE_RENOVACION'
export const CLEAN_DATA_RUTA = 'CLEAN_DATA_RUTA'
export const CLEAN_DATA_RENOVACION = 'CLEAN_DATA_RENOVACION'
export const SET_DATA_RENOVACION = 'SET_DATA_RENOVACION'
export const REORDER_LIST_RUTA = 'REORDER_LIST_RUTA'
export const REORDER_DATA_RUTA = 'REORDER_DATA_RUTA'
export const SET_RENOVACION = 'SET_RENOVACION'
export const DELETE_RENOVACION = 'DELETE_RENOVACION'
export const DELETE_CREDITO = 'DELETE_CREDITO'
export const ADD_CLIENTE_CREDITO = 'ADD_CLIENTE_CREDITO'
export const REMOVE_CLIENTE_CREDITO = 'REMOVE_CLIENTE_CREDITO'
export const CLEAR_CLIENTES_CREDITO = 'CLEAR_CLIENTES_CREDITO'

// Flujo de caja
export const GET_FLUJO_CAJA = 'GET_FLUJO_CAJA'
export const SELECCIONAR_FLUJO_CAJA = 'SELECCIONAR_FLUJO_CAJA'
export const CHANGE_ATTR_FLUJO_CAJA = 'CHANGE_ATTR_FLUJO_CAJA'
export const NEW_FLUJO_CAJA = 'NEW_FLUJO_CAJA'
export const CLEAN_FLUJO_CAJA = 'CLEAN_FLUJO_CAJA'
export const CAMBIAR_PAGINA_FLUJO_CAJA = 'CAMBIAR_PAGINA_FLUJO_CAJA'


// Flujo de utilidades
export const GET_FLUJO_UTILIDADES = 'GET_FLUJO_UTILIDADES'
export const SELECCIONAR_FLUJO_UTILIDADES = 'SELECCIONAR_FLUJO_UTILIDADES'
export const CHANGE_ATTR_FLUJO_UTILIDADES = 'CHANGE_ATTR_FLUJO_UTILIDADES'
export const NEW_FLUJO_UTILIDADES = 'NEW_FLUJO_UTILIDADES'
export const CLEAN_FLUJO_UTILIDADES = 'CLEAN_FLUJO_UTILIDADES'
export const CAMBIAR_PAGINA_FLUJO_UTILIDADES = 'CAMBIAR_PAGINA_FLUJO_UTILIDADES'


//FILTRADO 
export const SHOW_HIDE_MODAL_MAESTRAS = 'SHOW_HIDE_MODAL_MAESTRAS'
export const ADICIONAR_CRITERIO_FILTRADO_MAESTRAS = 'ADICIONAR_CRITERIO_FILTRADO_MAESTRAS'
export const MODIFICAR_CRITERIO_FILTRADO_MAESTRAS = 'MODIFICAR_CRITERIO_FILTRADO_MAESTRAS'
export const ELIMINAR_CRITERIO_FILTRADO_MAESTRAS = 'ELIMINAR_CRITERIO_FILTRADO_MAESTRAS'
export const CONMUTAR_SELECCION_CRITERIO_FILTRADO_MAESTRAS = 'CONMUTAR_SELECCION_CRITERIO_FILTRADO_MAESTRAS'
export const UPDATED_MAESTRA_CLIENTES = 'UPDATED_MAESTRA_CLIENTES'
export const UPDATED_MAESTRA_RUTAS = 'UPDATED_MAESTRA_RUTAS'
export const UPDATED_MAESTRA_FLUJOCAJA = 'UPDATED_MAESTRA_FLUJOCAJA'
export const UPDATED_MAESTRA_FLUJOUTILIDADES = 'UPDATED_MAESTRA_FLUJOUTILIDADES'


//REPORTES
export const GET_DATA_COTEO = 'GET_DATA_COTEO'
export const GET_FECHAS_REPORTE = 'GET_FECHAS_REPORTE'