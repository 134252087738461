import React, { Component } from 'react';
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import TextField from '@material-ui/core/TextField';
import moment from 'moment'

//UI
import GridClientes from '../../components/Administracion/Clientes/GridClientes'
import AddClientes from '../../components/Administracion/Clientes/AddClientes'
import BoxButton from '../../components/Common/BoxButtonV2'
import Permisos from '../../components/Common/Permisos'
import Modal from '../../components/Common/Modal'
import BoxButtonV2 from '../../components/Common/BoxButtonV2'

import { CLIENTE } from '../../actions/index'
import { newRow, toogleSidebarRigth, toggleModal, changeAttr } from '../../actions/common'
import { saveAction, editCliente, cleanCliente, changeState, getDetallesClientes, saveActionReferencias } from '../../actions/clientes'
import { GetPermisosVistas } from '../../utils/helpers'


class Clientes extends Component {
  constructor(props) {
    super(props);
    this.actionNewRow = this.actionNewRow.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.actionEditCliente = this.actionEditCliente.bind(this);
    this.actionEstadoCliente = this.actionEstadoCliente.bind(this);
    this.actionToogleSidebarRigth = this.actionToogleSidebarRigth.bind(this);
    this.actionGetDetails = this.actionGetDetails.bind(this);
    this.toggleReferencia = this.toggleReferencia.bind(this);

    this.state = {
      _permisos: false,
      tipoModal: 0,
      tipoRef: ''
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ _permisos: GetPermisosVistas(this.props.user, "Clientes") });
  }

  actionNewRow(tipo) {
    this.props.toogleSidebarRigth();
    this.props.newRow(tipo)
    this.props.cleanCliente();
  }

  saveAction() {
    this.props.saveAction();
  }

  actionToogleSidebarRigth() {
    this.props.toogleSidebarRigth();
  }

  actionEditCliente(id) {
    this.props.editCliente(id);
    this.actionToogleSidebarRigth(0);
  }

  actionEstadoCliente(id) {
    if (this.state._permisos.editar) {
      Swal.fire({
        title: 'ESTA SEGURO QUE QUIERE CAMBIAR EL ESTADO AL CLIENTE?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar cambios'
      }).then((result) => {
        if (result.value) {
          this.props.changeState(id);
        }
      })
    }
  }

  save() {
    if(this.props.selectRowReferencia.get('nombre') !== "" && this.props.selectRowReferencia.get('parentesco') !== ""){
      this.props.saveActionReferencias(this.state.tipoRef)
    }else{
      Swal.fire('Debe completar toda la información')
    }
  }

  actionGetDetails(id) {
    if (id !== undefined) {
      this.props.getDetallesClientes(id);
      this.setState({ tipoModal: 1 })
      this.props.toggleModal();
    }
  }

  toggleReferencia(tipo) {
    this.setState({ tipoRef: tipo })
    this.props.newRow("REFERENCIA");
    this.setState({ tipoModal: 2 })
    this.props.toggleModal();
  }

  renderSwitch(type) {
    const buttonsModal = [
      <BoxButtonV2 key="bb[2][0]" name="save" onClick={() => this.save()} title="Agregar referencia" classCSS="info" />,
    ]

    switch (type) {
      case 1:
        return (
          <Modal title={"Historial de creditos"} brand={false} className="modal-lg" hideBody={true} >
            <table className="table table-bordered table-striped table-sm">
              <thead>
                <tr>
                  <th scope="col">Prestamo</th>
                  <th scope="col">Cuota</th>
                  <th scope="col">Dias</th>
                  <th scope="col">Total a pagar</th>
                  <th scope="col">Ruta</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Finalización</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.props.historial ? this.props.historial.toList().map((x) => {
                    return (
                      <tr key={x.get('Id')}>
                        <td>{x.get('ValorPrestamo')}</td>
                        <td>{x.get('ModCuota')}</td>
                        <td>{x.get('ModDias')}</td>
                        <td>{x.get('ModCuota') * x.get('ModDias')}</td>
                        <td>{x.get('RutaId')}</td>
                        <td align="center">
                          {
                            x.get('Activo') ? <span className={`badge ${CLIENTE()}`}>Activo</span> :
                              <span className="badge badge-danger">Inactivo</span>
                          }
                        </td>
                        <td align="center">{x.get('Finalizacion') ? moment(x.get('Finalizacion')).format('MM-DD-YYYY') : ""}</td>
                      </tr>
                    )
                  }) : null
                }
              </tbody>
            </table>
          </Modal>
        )
      case 2:
        return (
          <Modal title={"Agregar referencia " + this.state.tipoRef} buttons={buttonsModal} brand={true} >
            <div className="row">
              <div className="col-md-6">
                <TextField
                  error={this.props.selectRowReferencia !== undefined ? this.props.selectRowReferencia.get('nombre') ? false : true : null} margin="normal"
                  id="nombre" label="Nombre" size="small" fullWidth
                  value={this.props.selectRowReferencia === undefined || this.props.selectRowReferencia === null ? '' : this.props.selectRowReferencia.get('nombre')}
                  onChange={(e) => this.props.changeAttr("REFERENCIA", 'nombre', e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  error={this.props.selectRowReferencia !== undefined ? this.props.selectRowReferencia.get('direccion') ? false : true : null} margin="normal"
                  id="direccion" label="Dirección" size="small" fullWidth
                  value={this.props.selectRowReferencia === undefined || this.props.selectRowReferencia === null ? '' : this.props.selectRowReferencia.get('direccion')}
                  onChange={(e) => this.props.changeAttr("REFERENCIA", 'direccion', e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  error={this.props.selectRowReferencia !== undefined ? this.props.selectRowReferencia.get('barrio') ? false : true : null} margin="normal"
                  id="barrio" label="Barrio" size="small" fullWidth
                  value={this.props.selectRowReferencia === undefined || this.props.selectRowReferencia === null ? '' : this.props.selectRowReferencia.get('barrio')}
                  onChange={(e) => this.props.changeAttr("REFERENCIA", 'barrio', e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  error={this.props.selectRowReferencia !== undefined ? this.props.selectRowReferencia.get('telefono') ? false : true : null} margin="normal"
                  id="telefono" label="Teléfono" size="small" fullWidth
                  value={this.props.selectRowReferencia === undefined || this.props.selectRowReferencia === null ? '' : this.props.selectRowReferencia.get('telefono')}
                  onChange={(e) => this.props.changeAttr("REFERENCIA", 'telefono', e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  error={this.props.selectRowReferencia !== undefined ? this.props.selectRowReferencia.get('parentesco') ? false : true : null} margin="normal"
                  id="parentesco" label="Parentesco" size="small" fullWidth
                  value={this.props.selectRowReferencia === undefined || this.props.selectRowReferencia === null ? '' : this.props.selectRowReferencia.get('parentesco')}
                  onChange={(e) => this.props.changeAttr("REFERENCIA", 'parentesco', e.target.value)}
                />
              </div>
            </div>
          </Modal>
        )
      default:
        return (null);
    }
  }


  render() {
    const { list, creditosActivos } = this.props;
    
    if (!this.state._permisos.ver) {
      return (
        <Permisos ref="" />
      )
    } else {
      return (
        <div className="col-xl-12 col-lg-12">
          <GridClientes actionGetDetails={this.actionGetDetails} creditosActivos={creditosActivos} list1={list} actionNewRow={this.actionNewRow} actionEditCliente={this.actionEditCliente} actionEstadoCliente={this.actionEstadoCliente} user={this.state._permisos} />
          {
            this.props.sidebarRigth ?
              <div className="sidenav-fondo">
                <div className="sidenav">
                  <div className="row">
                    <div className="col-md-9" style={{ paddingLeft: 25 }}>
                    </div>
                    <div className="col-md-3">
                      <div className="col">
                        <div className="float-right">
                          <BoxButton key="bp[0][0]" name="times" onClick={() => this.actionToogleSidebarRigth()} title="Cerrar" classCSS="info" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <AddClientes toggleReferencia={this.toggleReferencia} showReferencias={true} saveAction={this.saveAction} user={this.state._permisos} />
                </div>
              </div>
              : null
          }
          {this.renderSwitch(this.state.tipoModal)}
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    selectRow: state.clientes.get('selectRow'),
    list: state.clientes.get('list'),
    creditosActivos: state.clientes.get('creditosActivos'),
    sidebarRigth: state.common.get('toogleSidebarRigth'),
    historial: state.clientes.getIn(['list', String(state.clientes.get('selected')), 'creditos']),
    selectRowReferencia: state.clientes.get('selectRowReferencia'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newRow: (tipo) => dispatch(newRow(tipo)),
    editCliente: (id) => dispatch(editCliente(id)),
    changeState: (id) => dispatch(changeState(id)),
    cleanCliente: () => dispatch(cleanCliente()),
    saveAction: () => dispatch(saveAction()),
    toogleSidebarRigth: () => dispatch(toogleSidebarRigth()),
    getDetallesClientes: (id) => dispatch(getDetallesClientes(id)),
    toggleModal: () => dispatch(toggleModal()),
    saveActionReferencias: (tipo) => dispatch(saveActionReferencias(tipo)),
    changeAttr: (tipo, attr, value) => dispatch(changeAttr(tipo, attr, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Clientes)