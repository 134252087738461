import React, { Component } from 'react';
import { connect } from 'react-redux'
import image from '../assets/images/18.jpg'

import { changePassword } from '../actions/auth'
import { CLIENTE } from '../actions';

class Perfil extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cPassword: false,
            password: ""
        };
    }

    renderRol(user) {
        let rol = "";
        switch (user.Rol) {
            case 1:
                rol = "Administrador"
                break;
            case 2:
                rol = "Supervisor"
                break;
            case 3:
                rol = "Empleado"
                break;
        }

        return rol;
    }

    onSave() {
        this.props.changePassword(this.state.password)
        this.setState({ cPassword: false })
    }

    render() {
        const { user, rol } = this.props;
        return (
            <div className=" row col-md-12">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="media">
                                <div className="row">
                                    <div className="col-3">
                                        <img src={image} className="avatar avatar-xxl mr-5" />
                                    </div>
                                    <div className="col-9">
                                        <div className="media-body">
                                            <h4 className="m-0">{user.Nombres + " " + user.Apellidos}</h4>
                                            <p className="text-muted mb-0">{this.renderRol(user)}</p>
                                            <button className={`btn btn-icon btn-sm btn-block ${CLIENTE()}`} onClick={() => this.setState({ cPassword: !this.state.cPassword })}>
                                                <span className={`fas fa-unlock-alt`}></span> Modificar contraseña
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <form className="card">
                        <div className="card-body">
                            <h3 className="card-title">PERFIL DE USUARIO</h3>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="nombres">Nombres</label>
                                        <input type="text" className="form-control" id="nombres" value={user.Nombres} readOnly />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="apellidos">Apellidos</label>
                                        <input type="text" className="form-control" id="apellidos" value={user.Apellidos} readOnly />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="username">Username</label>
                                        <input type="text" className="form-control" id="username" value={user.Username} readOnly />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="telefono1">Telefono1</label>
                                        <input type="number" className="form-control" id="telefono1" value={user.Telefono1} readOnly />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="telefono2">Telefono2</label>
                                        <input type="number" className="form-control" id="telefono2" value={user.Telefono2} readOnly />
                                    </div>
                                </div>
                                {
                                    this.state.cPassword ?
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="newPass">Contraseña</label>
                                                <input type="text" className="form-control" placeholder="Nueva contraseña" onChange={(e) => this.setState({ password: e.target.value })} />
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <button className={`btn btn-icon ${CLIENTE()}`} type="button" onClick={() => this.onSave()} disabled={!this.state.cPassword}>
                                <span className={`fas fa-save`}></span>  Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        rol: state.auth.rol,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changePassword: (pass) => dispatch(changePassword(pass)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Perfil)