import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
    height: '100%'
  },
});

export default function DenseTable({rows, action}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small">
        <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {/* <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                /> */}
              </TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell align="right">Valor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.toJS().map((row) => (
              <TableRow key={row.ClienteId}>
                <TableCell component="th" scope="row" align="left">
                  <DeleteForeverIcon onClick={() => action(row.ClienteId)} />
                </TableCell>
                <TableCell >
                  {row.Cliente}
                </TableCell>
                <TableCell align="right">{row.ValorPrestamo}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}