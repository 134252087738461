import * as types from './types'
import {
    createAxiosInstance
} from '../utils/helpers'
import {
    toggleModal,
    setLoading
} from './common'

import {
    API_URL,
    messageHandler
} from './index'

import objectifyArray from 'objectify-array'
// import moment from 'moment'

const axios = createAxiosInstance();


export function getCreditos(id) {
    function getCredit() {
        return axios.get(`${API_URL}/creditos/${id}`);
    }

    return (dispatch) => {
        dispatch(setLoading(true));
        Promise.all([getCredit()])
            .then((res) => {
                const data = objectifyArray(res[0].data.data, {
                    by: ['id'],
                    recursive: true
                })

                dispatch(setLoading(false));

                dispatch({
                    type: types.GET_RUTAS,
                    payload: {
                        data,
                        id,
                        cobrador: res[0].data.cobrador,
                        nuevo: 0
                    }
                })
            })
            .catch((err) => {
                dispatch(setLoading(false));
                messageHandler(dispatch, err)
            })
    }
}

export function getListRutas() {
    return (dispatch) => {

        axios.get(`${API_URL}/parametros/Rutas`)
            .then((res) => {
                dispatch({
                    type: types.GET_LISTA_RUTAS,
                    payload: {
                        data: res.data
                    }
                })

            })
            .catch((err) => {
                messageHandler(dispatch, err)
            });
    }
}

export function getListPeriodos() {
    return (dispatch) => {

        axios.get(`${API_URL}/parametros/periodos`)
            .then((res) => {
                dispatch({
                    type: types.GET_LISTA_PERIODOS,
                    payload: {
                        data: res.data.data,
                        obsDias: res.data.dias
                    }
                })

            })
            .catch((err) => {
                messageHandler(dispatch, err)
            });
    }
}

export function getClientes() {
    return (dispatch) => {
        axios.get(`${API_URL}/creditos/clientes1`)
            .then((res) => {
                dispatch({
                    type: types.GET_CLIENTES_RUTA,
                    payload: {
                        data: res.data.data
                    }
                })
            })
            .catch((err) => {
                messageHandler(dispatch, err)
            })
    }
}

export function saveCredito() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        let rows = getState().rutas.get('rows').toJS();
        let ruta = getState().rutas.get('idRuta');

        axios.post(`${API_URL}/creditos`, rows)
            .then((res) => {
                dispatch(setLoading(false));
                const data = objectifyArray(res.data.data, {
                    by: ['id'],
                    recursive: true
                })
                dispatch(toggleModal());
                dispatch(clearNewClientesCreditos());
                dispatch({
                    type: types.GET_RUTAS,
                    payload: {
                        data,
                        id: ruta,
                        cobrador: res.data.cobrador,
                        nuevo: rows.map(item => item.ValorPrestamo).reduce((prev, curr) => prev + curr, 0)
                    }
                })

                messageHandler(dispatch, {
                    success: 'Clientes adicionados a la ruta'
                })
            })
            .catch((err) => {
                dispatch(setLoading(false));
                messageHandler(dispatch, err)
            })
    }
}

export function saveAbonos(entrada, salida, utilidad, coteos, moras) {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        let rows = getState().rutas.get('list').sortBy(
            (f) => f.get('orden')
        ).toList().toJS();
        const id = getState().rutas.get('idRuta');
        const user = getState().auth.user;

        const dataToSend = []
        const renovaciones = []
        const eliminar = []

        rows.map((x) => {
            if (x.delete) {
                eliminar.push({
                    Id: x.id
                })
            } else {
                dataToSend.push({
                    Id: x.id,
                    Cuota: x.cuota ? Number(x.cuota) * 1000 : null,
                    Orden: x.orden,
                    Obs: x.obs_dia,
                    Congelar: x.congelar
                })
                if (x.renovacion) {
                    renovaciones.push({
                        Id: x.id,
                        Excedente: x.renovacion.monto * 1000,
                        Observaciones: x.renovacion.observaciones,
                        Modalidad: x.renovacion.modalidad,
                        Dias: Number(x.renovacion.dias),
                        Cuota: x.renovacion.cuota * 1000,
                        ValorPrestamo: x.renovacion.valor * 1000,
                        Utilidad: x.renovacion.editable ? Number(x.renovacion.utilidad) : 0
                    })
                }
            }
        });
        axios.post(`${API_URL}/creditos/abonos`, {
            IdRuta: Number(id),
            Abonos: dataToSend,
            CalculoMoras: moras,
            Renovaciones: renovaciones,
            Eliminar: eliminar,
            FlujoCaja: {
                Entrada: entrada,
                Salida: salida,
                Utilidad: utilidad,
                Coteos: coteos
            },
            User: user.Id,
        })
            .then((res) => {
                dispatch(setLoading(false));
                const data = objectifyArray(res.data.data, {
                    by: ['id'],
                    recursive: true
                });
                dispatch({
                    type: types.GET_RUTAS,
                    payload: {
                        data,
                        id,
                        cobrador: res.data.cobrador,
                        nuevo: 0
                    }
                })

                messageHandler(dispatch, {
                    success: 'Se han guadado los abonos de la ruta'
                })
            })
            .catch((err) => {
                dispatch(setLoading(false));
                messageHandler(dispatch, err)
            })
    }
}

export function reorderDataDB() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        let rows = getState().rutas.get('list').valueSeq().toJS();
        const id = getState().rutas.get('idRuta');
        const dataToSend = []
        rows.map((x) => {
            dataToSend.push(x.id);
        });

        axios.post(`${API_URL}/creditos/reorder`, {
                'data': dataToSend,
                'idRuta': id
            })
            .then((res) => {
                dispatch(setLoading(false));
                const data = objectifyArray(res.data.data, {
                    by: ['id'],
                    recursive: true
                });
                dispatch({
                    type: types.GET_RUTAS,
                    payload: {
                        data,
                        id,
                        cobrador: res.data.cobrador,
                        nuevo: 0
                    }
                })

                messageHandler(dispatch, {
                    success: 'Se han ordenado los creditos'
                })
            })
            .catch((err) => {
                dispatch(setLoading(false));
                messageHandler(dispatch, err)
            })
    }
}

export function saveRenovacion(id) {
    return (dispatch) => {
        axios.post(`${API_URL}/creditos/renovaciones/` + Number(id))
            .then((res) => {
                dispatch(toggleModal());
            })
            .catch((err) => {
                messageHandler(dispatch, err)
            })
    }
}

export function saveRenovacion1(id) {
    return (dispatch) => {
        dispatch({
            type: types.SET_RENOVACION,
            payload: {
                id
            }
        });
        dispatch(toggleModal());
    }
}

export function saveRenovacionInmediata(id) {
    return (dispatch) => {
        axios.post(`${API_URL}/creditos/renovaciones/` + Number(id))
            .then((res) => {
                dispatch({
                    type: types.SET_DATA_RENOVACION,
                    payload: {
                        id
                    }
                });
            })
            .catch((err) => {
                messageHandler(dispatch, err)
            })
    }
}

export function deleteRenovacion(id) {
    return (dispatch) => {
        dispatch({
            type: types.DELETE_RENOVACION,
            payload: {
                id
            }
        });
    }
}

export function deleteCredito(id) {
    return (dispatch) => {
        dispatch({
            type: types.DELETE_CREDITO,
            payload: {
                id
            }
        });
    }
}

export function getDetallesRuta() {
    return (dispatch) => {
        dispatch({
            type: types.GET_DETALLES_RUTAS
        })
    }
}

export function getDetallesRenovaciones() {
    return (dispatch) => {
        dispatch({
            type: types.GET_DETALLE_RENOVACION
        })
    }
}

export function selectCliente(id) {
    return (dispatch) => {
        dispatch({
            type: types.SELECT_CLIENTE,
            payload: {
                id
            }
        })
    }
}

export function cleanDataRutas() {
    return (dispatch) => {
        dispatch({
            type: types.CLEAN_DATA_RUTA
        })
    }
}

export function cleanRenovacion(id) {
    return (dispatch) => {
        dispatch({
            type: types.CLEAN_DATA_RENOVACION,
            payload: {
                id
            }
        })
    }
}

export function reorderList(list) {
    return (dispatch) => {
        dispatch({
            type: types.REORDER_LIST_RUTA,
            payload: list
        })
    }
}

export function reorderData() {
    return (dispatch) => {
        dispatch({
            type: types.REORDER_DATA_RUTA
        })
    }
}

export function addListClientes() {
    return (dispatch) => {
        dispatch({
            type: types.ADD_CLIENTE_CREDITO
        })
    }
}

export function removeCliente(index) {
    return (dispatch) => {
        dispatch({
            type: types.REMOVE_CLIENTE_CREDITO,
            payload: {
                ClienteId: index
            }
        })
    }
}

export function clearNewClientesCreditos() {
    return (dispatch) => {
        dispatch({
            type: types.CLEAR_CLIENTES_CREDITO
        })
    }
}