import React, { Component } from 'react';
import { connect } from 'react-redux'
import { tableColumnsUsuarios as tableColumns } from '../../utils/headersColumns'

//UI
import BoxButton from '../../components/Common/BoxButtonV2'
import TableVirtualized from '../../components/Common/TableVirtualized'
import Permisos from '../../components/Common/Permisos'

//DATA
import { CLIENTE } from '../../actions/index'
import { getUsuarios, saveAction, getListRoles, getListViews, changeCheck, savePermission } from '../../actions/usuarios'
import { getListRutas } from '../../actions/rutas'
import { selectAction, changeAttr, newRow } from '../../actions/common'
import { GetPermisosVistas } from '../../utils/helpers'


class Personas extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editPermission: false
        }
    }

    componentWillMount() {
        this.setState({ _permisos: GetPermisosVistas(this.props.user, "Usuarios") });
    }

    componentDidMount() {
        if (this.state._permisos.ver) {
            this.props.getUsuarios();
            this.props.getListRutas();
            this.props.getListRoles();
        }
    }

    onGetViews(row = null, search = false) {
        if (search) {
            row = row.toJS();
            this.props.getListViews(row.rol, this.props.selectRow.get("id"));
        }
        this.setState({ editPermission: !this.state.editPermission });
    }

    findPermissions(id, tipo) {
        let rest = false;
        const permisos = this.props.permisos.toJS();

        var find = permisos.find(row => row.rol_permiso_id == id);
        if (find) {
            switch (tipo) {
                case "ver":
                    rest = find.ver;
                    break;
                case "editar":
                    rest = find.editar;
                    break;
                default:
                    break;
            }
        }

        return rest
    }

    onSaveAction() {
        this.props.savePermission();
        this.setState({ editPermission: !this.state.editPermission });
    }

    render() {
        const { list, ids, selected, selectRow, changeAttr, saveAction, newRow, rutas, roles, permisos, changeCheck } = this.props;
        const tipo = "USUARIO";

        console.log(roles.toJS())
        let buttons = []
        if (this.state._permisos.editar) {
            buttons = [
                <BoxButton key="bp[0][0]" name="plus" onClick={() => newRow(tipo)} title="Agregar nuevo" classCSS="info" />,
                <BoxButton key="bp[0][1]" name="save" onClick={() => saveAction()} title="Guardar" classCSS="success" disabled={selectRow !== null ? false : true} />,
            ]
        }

        if (!this.state._permisos.ver) {
            return (
                <Permisos ref="dataExport" />
            )
        } else {
            return (
                <div className="col-md-12 col-xs-12">
                    {
                        this.state.editPermission ?
                            <div className="sidenav-fondo" style={{ paddingTop: 0 }}>
                                <div className="sidenav sn-xs">
                                    <div className="card border-left-success" style={{ marginTop: -5 }}>
                                        <div className="card-header">
                                            <h3 className={`card-title ${CLIENTE(1)}`}>Editar permisos de usuario</h3>
                                            <div className="card-options">
                                                <BoxButton key="bp[0][1]" name="times" onClick={() => this.onGetViews()} title="Cerrar" classCSS="info" />
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table card-table table-striped table-center">
                                                <thead>
                                                    <tr>
                                                        <th>Vista</th>
                                                        <th>Ver</th>
                                                        <th>Editar/Guardar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        permisos.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.get("view")}</td>
                                                                    <td>
                                                                        <label className="custom-switch">
                                                                            <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input" checked={item.get("ver")} onChange={(e) => changeCheck(index, "ver", e.target.checked)} />
                                                                            <span className="custom-switch-indicator"></span>
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <label className="custom-switch">
                                                                            <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input" checked={item.get("editar")} onChange={(e) => changeCheck(index, "editar", e.target.checked)} />
                                                                            <span className="custom-switch-indicator"></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="card-footer">
                                            <button type="button" className={`btn btn-icon ${CLIENTE()}`} onClick={() => this.onSaveAction()} >
                                                <i className="fa fa-save"></i> Guardar
                                    </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                    <div className="card shadow border-left-success mb-4">
                        <div className={`card-status card-status-left ${CLIENTE()}`}></div>
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className={`m-0 ${CLIENTE(1)}`}>Administración de usuarios y personas</h6>
                            <div className="card-options">
                                {
                                    buttons.map(item => {
                                        return (item)
                                    })
                                }
                            </div>
                        </div>
                        <div className="row" style={{ paddingLeft: 2, height: "calc(100vh - 187px)", maxHeight: "calc(100vh - 187px)" }}>
                            <div className="col-md-6">
                                <TableVirtualized
                                    tableColumns={tableColumns}
                                    ids={ids}
                                    list={list}
                                    keyVal="id"
                                    actionSelect={this.props.selectAction}
                                    selected={selected}
                                    tipo={tipo}
                                />
                            </div>
                            {
                                selectRow !== null ?
                                    <div className="col-md-6" style={{ paddingRight: 30 }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label >Nombres</label>
                                                <input className="form-control form-control-sm" type="text" value={selectRow.get('nombres')} onChange={(e) => changeAttr(tipo, 'nombres', e.target.value)} ></input>
                                            </div>
                                            <div className="col-md-6">
                                                <label >Apellidos</label>
                                                <input className="form-control form-control-sm" type="text" value={selectRow.get('apellidos')} onChange={(e) => changeAttr(tipo, 'apellidos', e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label >Telefono 1</label>
                                                <input className="form-control form-control-sm" type="number" value={selectRow.get('telefono1')} onChange={(e) => changeAttr(tipo, 'telefono1', e.target.value)}></input>
                                            </div>
                                            <div className="col-md-6">
                                                <label >Telefono 2</label>
                                                <input className="form-control form-control-sm" type="number" value={selectRow.get('telefono2')} onChange={(e) => changeAttr(tipo, 'telefono2', e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-auto my-1">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" className="custom-control-input" id="login" checked={selectRow.get('login')} onChange={(checked) => changeAttr(tipo, 'login', checked.target.checked)} />
                                                    <label className="custom-control-label" htmlFor="login">Es usuario de la aplicación?</label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            selectRow.get('login') ?
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label >Email</label>
                                                        <input className="form-control form-control-sm" type="text" value={selectRow.get('email')} onChange={(e) => changeAttr(tipo, 'email', e.target.value)}></input>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label >Rol</label>
                                                        <select className="form-control form-control-sm" value={selectRow !== null ? selectRow.get('rol') : 0} onChange={(e) => changeAttr(tipo, 'rol', e.target.value)} >
                                                            <option value={0} key={0}>Seleccione...</option>
                                                            {
                                                                roles.map((x) => {
                                                                    return (
                                                                        <option value={x.get("value")} key={x.get("value")}>{x.get("label")}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label >Nombre de usuario</label>
                                                        <input className="form-control form-control-sm" type="text" value={selectRow.get('username')} onChange={(e) => changeAttr(tipo, 'username', e.target.value)} ></input>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label >Contraseña</label>
                                                        <input className="form-control form-control-sm" type="password" value={selectRow.get('password')} onChange={(e) => changeAttr(tipo, 'password', e.target.value)} ></input>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <button className={`btn btn-icon btn-block ${CLIENTE()}`} onClick={() => this.onGetViews(selectRow, true)}>
                                                            <i className="fa fa-cog"></i> Editar permisos de usuario
                                                    </button>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label >Ruta</label>
                                                        <select className="form-control form-control-sm" value={selectRow !== null ? selectRow.get('ruta') : 0} onChange={(e) => changeAttr(tipo, 'ruta', e.target.value)} >
                                                            <option value={0} key={0}>Seleccione...</option>
                                                            {
                                                                rutas.map((x) => {
                                                                    return (
                                                                        <option value={x.get("value")} key={x.get("value")}>{x.get("label")}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    : null
                            }

                        </div>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        list: state.usuarios.get('list'),
        ids: state.usuarios.get('ids'),
        selected: state.usuarios.get('selected'),
        selectRow: state.usuarios.get('selectRow'),
        rutas: state.rutas.get('rutas'),
        roles: state.usuarios.get('roles'),
        permisos: state.usuarios.get('permisos')
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUsuarios: () => dispatch(getUsuarios()),
        selectAction: (id, reloadGrid, tipo) => dispatch(selectAction(id, reloadGrid, tipo)),
        newRow: (tipo) => dispatch(newRow(tipo)),
        saveAction: () => dispatch(saveAction()),
        changeAttr: (tipo, attr, value) => dispatch(changeAttr(tipo, attr, value)),
        getListRutas: () => dispatch(getListRutas()),
        getListRoles: () => dispatch(getListRoles()),
        getListViews: (id, user) => dispatch(getListViews(id, user)),
        changeCheck: (index, tipo, value) => dispatch(changeCheck(index, tipo, value)),
        savePermission: () => dispatch(savePermission())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Personas)