import React, { Component } from 'react'

export default class Permisos extends Component {

    render() {
        let { ref } = this.props;
        return (
            <div className="text-center" style={{ height: "calc(100vh - 150px)" }} ref={ref}>
                <div className="display-1 text-muted mb-5">
                    <i className="fas fa-ban"></i>
                </div>
                <h1 className="h2 mb-3">Oops.. No tiene permisos suficientes!</h1>
                <p className="h4 text-muted font-weight-normal mb-7">Por favor contacte con su administrador para mas detalles&hellip;</p>
                <a className="btn btn-primary" href="/">
                    <i className="fe fe-arrow-left mr-2"></i>Volver al inicio
                </a>
            </div>
        )
    }
}