import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'
import { exportCoteos, GetPermisosVistas } from '../../utils/helpers'
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';

//UI
import TablesCoteo from '../../components/Reportes/TablesCoteo'
import TablesUtilidadRecaudo from '../../components/Reportes/TablesUtilidadRecaudo'
import TablesNuevosRenovaciones from '../../components/Reportes/TablesNuevosRenovaciones'
import Permisos from '../../components/Common/Permisos'
import BoxButtonV2 from '../../components/Common/BoxButtonV2'

import { getCoteos, getListFechas } from '../../actions/reportes'
import { CLIENTE } from '../../actions/index'

var getDates = function (startDate, endDate) {
    var dates = [],
        currentDate = startDate,
        addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
    while (currentDate <= endDate) {
        dates.push(moment(currentDate).format('YYYY-MM-DD'));
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
};

class Coteos extends Component {

    constructor(props) {
        super(props);
        this.onGetDate = this.onGetDate.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.hideMainMenu = this.hideMainMenu.bind(this);
        this.onGetTeporte = this.onGetTeporte.bind(this);

        this.state = {
            showMainMenu: true,
            tabs: [
                { id: 0, caption: 'Corte 1', active: true },
                { id: 1, caption: 'Corte 2', active: false },
                { id: 2, caption: 'Corte 3', active: false },
                { id: 3, caption: 'UTILIDAD', active: false },
                { id: 4, caption: 'RECAUDO', active: false },
                { id: 5, caption: 'NUEVOS Y REN', active: false },
            ],
            tab: 0,
            dates: [],
            year: (new Date()).getFullYear(),
            _permisos: false
        };
    }

    componentWillMount() {
        this.setState({ _permisos: GetPermisosVistas(this.props.user, "Coteos") });
    }

    componentDidMount() {
        if (this.state._permisos.ver) {
            this.props.getListFechas();
        }
    }

    changeTab(tab) {
        if (this.props.selectRow !== null) {
            let tabs = this.state.tabs.map(x => {
                x.active = false;
                return x;
            })
            tabs[tabs.findIndex(x => x.id === tab.id)].active = true;
            this.setState({ tabs, tab: tab.id });
        }
    }

    hideMainMenu() {
        this.setState({ showMainMenu: !this.state.showMainMenu })
    }

    onGetDate(month) {
        var y = this.state.year;
        var firstDay = new Date(y, Number(month), 1);
        var lastDay = new Date(y, Number(month) + 1, 0);
        this.setState({ dates: getDates(firstDay, lastDay) });
    }

    onGetTeporte() {
        this.setState({ showMainMenu: !this.state.showMainMenu });
        this.props.getCoteos(this.state.dates[0], this.state.dates[this.state.dates.length - 1]);
    }

    render() {
        const { tabs, tab } = this.state;
        const { list, list1, nuevos, renovaciones, fechasR } = this.props;

        const dates1 = this.state.dates.slice(0, 10);
        const dates2 = this.state.dates.slice(10, 20)
        const dates3 = this.state.dates.slice(20);
        const fechas = [dates1[dates1.length - 1], dates2[dates2.length - 1], dates3[dates3.length - 1]]

       
        tabs[0].component = <TablesCoteo title="CLIENTES  CORTE 1" dates={dates1} />;
        tabs[1].component = <TablesCoteo title="CLIENTES  CORTE 2" dates={dates2} />;
        tabs[2].component = <TablesCoteo title="CLIENTES  CORTE 3" dates={dates3} />;
        tabs[3].component = <TablesUtilidadRecaudo title="UTILIDAD RENOVACIONES" titles={["UTILIDAD CORTE 1", "UTILIDAD CORTE 2", "UTILIDAD CORTE 3"]} fechas={fechas} list={list} texto="Utilidad ruta " />;
        tabs[4].component = <TablesUtilidadRecaudo title="ACUMALADO RECAUDO" titles={["RECAUDO CORTE 1", "RECAUDO CORTE 2", "RECAUDO CORTE 3"]} fechas={fechas} list={list1} texto="Cobros ruta " />;
        tabs[5].component = <TablesNuevosRenovaciones title="NUEVOS Y RENOVACIONES" fechas={fechas} />;

        if (!this.state._permisos.ver) {
            return (
                <Permisos ref="dataExport" />
            )
        } else {
            return (
                <div>
                    {
                        !this.state.showMainMenu ?
                            null
                            :
                            <div className="sidenav-left">
                                <div className="card" style={{ height: "100%" }}>
                                    <div className="card-header">
                                        <h3 className="card-title">Opciones</h3>
                                        <div className="card-options">
                                            <BoxButtonV2 key="bp[0][0]" name="times" onClick={() => this.hideMainMenu()} title="Cerrar" classCSS="info" />
                                        </div>
                                    </div>
                                    <div style={{ paddingTop: 10, marginBottom: 0, background: '#f7f7f7', marginLeft: 0, marginRight: 0, paddingBottom: 0, height: '100%' }}>
                                        <div className="col-md-12">
                                            <label >Año:</label>
                                            <div className="form-group">
                                                <select className="form-control form-control-sm" value={this.state.year} id="exampleFormControlSelect1" onChange={(e) => this.setState({ year: e.target.value })}>
                                                    <option value="">Seleccione...</option>
                                                    {
                                                        fechasR != "" ? fechasR.map((item) => {
                                                            return (
                                                                <option key={item.get('label')} value={item.get('label')}>{item.get('label')}</option>
                                                            )
                                                        }) : null
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label >Mes:</label>
                                            <div className="form-group">
                                                <select className="form-control form-control-sm" id="exampleFormControlSelect1" onChange={(e) => this.onGetDate(e.target.value)}>
                                                    <option value="">Seleccione...</option>
                                                    <option value="0">Enero</option>
                                                    <option value="1">Febrero</option>
                                                    <option value="2">Marzo</option>
                                                    <option value="3">Abril</option>
                                                    <option value="4">Mayo</option>
                                                    <option value="5">Junio</option>
                                                    <option value="6">Julio</option>
                                                    <option value="7">Agosto</option>
                                                    <option value="8">Septiembre</option>
                                                    <option value="9">Octubre</option>
                                                    <option value="10">Noviembre</option>
                                                    <option value="11">Diciembre</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer text-right">
                                        <button type="button" className={`btn btn-icon ${CLIENTE()}`} onClick={() => this.onGetTeporte()} disabled={this.state.dates.length > 0 ? false : true}>
                                            <i className="fa fa-save"></i> Ver reporte
                                    </button>
                                    </div>
                                </div>
                            </div>
                    }
                    <div className="card shadow">
                        <div className={`card-status card-status-left ${CLIENTE()}`}></div>
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <IconButton size="large" className={`${CLIENTE(1)}`}>
                                <SettingsIcon onClick={() => this.hideMainMenu()} />
                            </IconButton>
                            <h5 className={`m-0 ${CLIENTE(1)}`}>Reporte de coteos</h5>
                            <div className="card-options">
                                <BoxButtonV2 key="bb[0][0]" name="file-pdf" onClick={() => exportCoteos(this.props.data, this.state.dates, fechas, list, list1, nuevos, renovaciones)} title="Exportar" classCSS="success" />
                            </div>
                        </div>
                        <nav>
                            <div className="nav nav-tabs nav-fill nav-tabs-component" id="nav-tab" role="tablist">
                                {
                                    tabs.map(x => {
                                        return (
                                            <a
                                                key={`li01[${x.id}]`}
                                                className={x.active ? `nav-item nav-link active ${CLIENTE(2)}` : "nav-item nav-link"}
                                                role="tab"
                                                onClick={(e) => this.changeTab(x)}
                                                aria-expanded={x.active ? "true" : "false"}>
                                                {x.caption}
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </nav>
                        <div className="tab-content">
                            <div className="tab-pane fade show active table-responsive" style={{ height: 'calc(100vh - 246px)' }} onClick={() => this.sav}>
                                {tabs[tab].component}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        data: state.reportes.get('dataCoteo'),
        list: state.reportes.get('utilidades'),
        list1: state.reportes.get('recaudos'),
        nuevos: state.reportes.get('nuevos'),
        renovaciones: state.reportes.get('renovaciones'),
        fechasR: state.reportes.get('fechas'),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCoteos: (firstDay, lastDay) => dispatch(getCoteos(firstDay, lastDay)),
        getListFechas: () => dispatch(getListFechas()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Coteos)