import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactDataGrid from 'react-data-grid';
import { Editors } from "react-data-grid-addons";

const { DropDownEditor } = Editors;

const issueTypes = [
    { id: "1", value: "Activo" },
    { id: "0", value: "Inactivo" }
  ];

const IssueTypeEditor = <DropDownEditor options={issueTypes} />;


const EstadoFormatter = ({ row }) => {
    return (
        row.estado ? "Activo" : "Inactivo"
    );
};

const columns = [
    { key: 'id_interno', name: 'Id', editable: false, width: 50, frozen: false },
    { key: 'valor', name: 'Valor', editable: true, frozen: false },
    { key: 'estado', name: 'Estado', editable: true, frozen: false, editor: IssueTypeEditor, formatter: EstadoFormatter },
];

class DataGrid extends Component {

    static defaultProps = { rowKey: "id" };

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const id = this.props.ids.get(toRow);
        const column = Object.keys(updated)[0];
        let valor = updated[column];

        if(column === "estado"){
            valor = valor === "Activo" ? 1 : 0;
        }
        this.props.changeAction('PARAMETRO', id, column, valor)
    };

    render() {
        const data = this.props.rows.toList().toJS().sort(function (a, b) { return a.id - b.id })

        return (
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => data[i]}
                rowsCount={data.length}
                onGridRowsUpdated={this.onGridRowsUpdated}
                enableCellSelect={true}
                rowHeight={25}
            />
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DataGrid)