import React, { Component } from 'react';
import { connect } from 'react-redux'

//UI
import Modal from '../../components/Common/Modal'
import BoxButtonV2 from '../../components/Common/BoxButtonV2'
import ListaDetalles from '../../components/Administracion/Parametros/ListaDetalles'
import Permisos from '../../components/Common/Permisos'

import { CLIENTE } from '../../actions/index'
import { getParametros, saveAction, updatedAction } from '../../actions/parametros'
import { selectAction, changeAttr2, changeAttr, toggleModal, newRow } from '../../actions/common'
import { GetPermisosVistas } from '../../utils/helpers'

class Maestras extends Component {
  constructor(props) {
    super(props);

    this.lisActionClick = this.lisActionClick.bind(this);
    this.changeAction = this.changeAction.bind(this);

    this.state = {
      _permisos: false
    };
  }

  componentWillMount() {
    this.setState({ _permisos: GetPermisosVistas(this.props.user, "Maestras") });
  }

  componentDidMount() {
    if (this.state._permisos.ver) {
      this.props.getParametros();
    }
  }

  lisActionClick(id) {
    this.props.selectAction(id, null, 'PARAMETRO')
  }

  changeAction(tipo, id, attr, value) {
    this.props.changeAttr2(tipo, id, attr, value)
  }

  newParametro() {
    this.props.toggleModal();
    this.props.newRow('PARAMETRO');
  }

  saveAction() {
    this.props.saveAction();
    this.props.toggleModal();
  }

  render() {
    let buttons = []

    if (this.state._permisos.editar) {
      buttons = [
        <BoxButtonV2 key="bb[0][0]" name="plus" onClick={() => this.newParametro()} title="Agregar" classCSS="success" disabled={this.props.editable ? false : true} />,
        <BoxButtonV2 key="bb[0][1]" name="save" onClick={() => this.props.updatedAction()} title="Guardar cambios" classCSS="success" disabled={this.props.editable ? false : true} />,
      ]
    }

    const buttons1 = [
      <BoxButtonV2 key="bb[1][0]" name="save" onClick={() => this.saveAction()} title="Guardar" />,
    ]

    const { list, ids, selectRow, editable, selected, newRowParametro, changeAttr } = this.props;

    if (!this.state._permisos.ver) {
      return (
        <Permisos ref="dataExport" />
      )
    } else {
      return (
        <div className="row col-md-12 col-xs-12">
          <div className="col-md-6 col-xs-6">
            <div className="card shadow border-left-success">
              <div className={`card-status card-status-left ${CLIENTE()}`}></div>
              <div className="card-header">
                <h4 className="card-title">Configuración de parámetros</h4>
              </div>
              <table className="table card-table">
                <tbody>
                  {
                    list.valueSeq().map(item => {
                      return (
                        <tr key={item.get('id')} onClick={() => this.lisActionClick(item.get('id'))}>
                          <td width="1"><i className={`fas fa-${item.get('icono')} ${CLIENTE(1)} mx-1`}></i></td>
                          <td>{item.get('nombre')}</td>
                          <td className="text-right">
                            <span className={`badge ${CLIENTE()} badge-pill`}>{item.get('parametros_detalles').count()}</span>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-md-6 col-xs-6">
            <div className="card shadow border-left-success">
              <div className={`card-status card-status-left ${CLIENTE()}`}></div>
              <div className="card-header">
                <h4 className="card-title">Detalles de parámetro</h4>
                <div className="card-options">
                  {
                    buttons.map(item => {
                      return (item)
                    })
                  }
                </div>
              </div>
              {
                selectRow !== null ?
                  <ListaDetalles list={selectRow} ids={ids} changeAction={this.changeAction} editable={editable} />
                  :
                  null
              }
            </div>
          </div>
          <Modal title="Agregar Paramámetros" brand={true} buttons={buttons1}  >
            <div className="row">
              <div className="col">
                <label htmlFor="id_interno">Identificador interno</label>
                <input className="form-control form-control-sm" type="number" id="id_interno" readOnly value={newRowParametro !== null ? newRowParametro.get('id_interno') : ''} disabled />
              </div>
              <div className="col">
                <label htmlFor="valor">Valor</label>
                <input className="form-control form-control-sm" type="text" id="valor" value={newRowParametro !== null ? newRowParametro.get('valor') : ''} onChange={(e) => changeAttr("PARAMETRO", 'valor', e.target.value)} />
              </div>
            </div>
          </Modal>
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    list: state.parametros.get('list'),
    selectRow: state.parametros.get('selectRow'),
    newRowParametro: state.parametros.get('newRowParametro'),
    ids: state.parametros.get('ids'),
    editable: state.parametros.getIn(['list', String(state.parametros.get('selected')), 'editable']),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getParametros: () => dispatch(getParametros()),
    toggleModal: () => dispatch(toggleModal()),
    newRow: (tipo) => dispatch(newRow(tipo)),
    saveAction: () => dispatch(saveAction()),
    updatedAction: () => dispatch(updatedAction()),
    selectAction: (id, reloadGrid, tipo) => dispatch(selectAction(id, reloadGrid, tipo)),
    changeAttr2: (tipo, id, attr, value) => dispatch(changeAttr2(tipo, id, attr, value)),
    changeAttr: (tipo, attr, value) => dispatch(changeAttr(tipo, attr, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Maestras)