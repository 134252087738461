import React, { Component } from 'react';
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';

// UI
import BoxButtonV2 from '../../Common/BoxButtonV2'
import TableVirtualized from '../../Common/TableVirtualized'

import { changeAttr, toggleModal, selectAction, editRow } from '../../../actions/common'
import { CLIENTE } from '../../../actions/index'
// import {  } from '../../../actions/clientes'
import { tableColumnsReferenciasCliente } from '../../../utils/headersColumns'

class AddClientes extends Component {

    constructor(props) {
        super(props);
        this.actionClick = this.actionClick.bind(this);

        this.state = {
            // tipoRef: ''
        }
    }

    actionClick(id) {
        this.props.editRow("REFERENCIA", id);
        this.props.toggleModal();
    }

    render() {
        let listTitular, idsTitular, listFiador, idsFiador;
        const buttonsTitular = [
            <BoxButtonV2 key="bb[0][0]" name="plus" onClick={() => this.props.toggleReferencia('TITULAR')} title="Agregar referencia" classCSS="info" />,
        ]

        const buttonsFiador = [
            <BoxButtonV2 key="bb[1][0]" name="plus" onClick={() => this.props.toggleReferencia('FIADOR')} title="Agregar referencia" classCSS="info" />,
        ]

        const { changeAttr, saveAction, selectRow, edit, selectedTitular, selectedFiador, selectAction, showReferencias, user } = this.props;
        const tipo = "CLIENTE";
        const list = selectRow.get('clientes_referencias');
        
        if (list !== undefined) {
            listTitular = list.filter(x => x.get('tipo_referencia') === 'TITULAR');
            idsTitular = listTitular.sortBy(x => x.get('id')).keySeq().toList();

            listFiador = list.filter(x => x.get('tipo_referencia') === 'FIADOR');
            idsFiador = listFiador.sortBy(x => x.get('id')).keySeq().toList();
        }

        return (
            <div className="col-md-12 col-xl-12" style={{ marginTop: 10 }}>
                <div className="card">
                    <div className="card-body">
                        <h3 className="card-title">INFORMACIÓN BÁSICA</h3>
                        <div className="row">
                            <div className="col-md-4">
                                <TextField
                                    error={selectRow.get('titular') ? false : true}
                                    id="titular" label="Titular" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('titular')}
                                    onChange={(e) => changeAttr(tipo, 'titular', e.target.value)}
                                />
                            </div>
                            <div className="col-md-2">
                                <TextField
                                    error={selectRow.get('cc_titular') ? false : true} type="number"
                                    id="ccTitular" label="Identificación" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('cc_titular')}
                                    onChange={(e) => changeAttr(tipo, 'cc_titular', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={selectRow.get('fiador') ? false : true}
                                    id="fiador" label="Fiador" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('fiador')}
                                    onChange={(e) => changeAttr(tipo, 'fiador', e.target.value)}
                                />
                            </div>
                            <div className="col-md-2">
                                <TextField
                                    error={selectRow.get('cc_fiador') ? false : true} type="number"
                                    id="ccFiador" label="Identificación" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('cc_fiador')}
                                    onChange={(e) => changeAttr(tipo, 'cc_fiador', e.target.value)}
                                />
                            </div>

                            <div className="col-md-6">
                                <TextField
                                    error={selectRow.get('neg_titular') ? false : true} margin="normal"
                                    id="negTitular" label="Negocio titular" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('neg_titular')}
                                    onChange={(e) => changeAttr(tipo, 'neg_titular', e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    error={selectRow.get('neg_fiador') ? false : true} margin="normal"
                                    id="negFiador" label="Negocio fiador" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('neg_fiador')}
                                    onChange={(e) => changeAttr(tipo, 'neg_fiador', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={selectRow.get('dir_cobro') ? false : true} margin="normal"
                                    id="dirCobro" label="Dirección de cobro" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('dir_cobro')}
                                    onChange={(e) => changeAttr(tipo, 'dir_cobro', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={selectRow.get('barrio_cobro') ? false : true} margin="normal"
                                    id="barrioCobro" label="Barrio" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('barrio_cobro')}
                                    onChange={(e) => changeAttr(tipo, 'barrio_cobro', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={selectRow.get('tel_cobro') ? false : true} margin="normal"
                                    id="telCobro" label="Teléfono" size="small" fullWidth type="number"
                                    value={selectRow === null ? '' : selectRow.get('tel_cobro')}
                                    onChange={(e) => changeAttr(tipo, 'tel_cobro', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={selectRow.get('dir_casa') ? false : true} margin="normal"
                                    id="dirCasa" label="Dirección de casa" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('dir_casa')}
                                    onChange={(e) => changeAttr(tipo, 'dir_casa', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={selectRow.get('barrio_casa') ? false : true} margin="normal"
                                    id="barrioCasa" label="Barrio" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('barrio_casa')}
                                    onChange={(e) => changeAttr(tipo, 'barrio_casa', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={selectRow.get('tel_casa') ? false : true} margin="normal"
                                    id="telCasa" label="Teléfono" size="small" fullWidth type="number"
                                    value={selectRow === null ? '' : selectRow.get('tel_casa')}
                                    onChange={(e) => changeAttr(tipo, 'tel_casa', e.target.value)}
                                />
                            </div>
                            <hr></hr>
                            <div className="col-md-4">
                                <TextField
                                    error={selectRow.get('dir_fiador') ? false : true} margin="normal"
                                    id="dirFiador" label="Dirección de fiador" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('dir_fiador')}
                                    onChange={(e) => changeAttr(tipo, 'dir_fiador', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={selectRow.get('barrio_fiador') ? false : true} margin="normal"
                                    id="barrioFiador" label="Barrio" size="small" fullWidth
                                    value={selectRow === null ? '' : selectRow.get('barrio_fiador')}
                                    onChange={(e) => changeAttr(tipo, 'barrio_fiador', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={selectRow.get('tel_fiador') ? false : true} margin="normal"
                                    id="telFiador" label="Teléfono" size="small" fullWidth type="number"
                                    value={selectRow === null ? '' : selectRow.get('tel_fiador')}
                                    onChange={(e) => changeAttr(tipo, 'tel_fiador', e.target.value)}
                                />
                            </div>
                        </div>
                        <hr />

                        {
                            showReferencias ?
                                <div className="row">
                                    <div className="col-md-12 col-xs-12 mb-4">
                                        <h3 className="card-title">REFERENCIAS</h3>
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                        <div className="card">
                                            <div className={`card-status ${CLIENTE()}`}></div>
                                            <div className="card-header">
                                                <h3 className="card-title">Titular</h3>
                                                <div className="card-options">
                                                    {
                                                        buttonsTitular[0]
                                                    }
                                                </div>
                                            </div>
                                            <div style={{ height: 180, maxHeight: 180 }} >
                                                {
                                                    list !== undefined ?
                                                        <TableVirtualized
                                                            tableColumns={tableColumnsReferenciasCliente}
                                                            ids={idsTitular}
                                                            list={listTitular}
                                                            keyVal="id"
                                                            actionSelect={selectAction}
                                                            actionClick={this.actionClick}
                                                            selected={selectedTitular}
                                                            tipo="CLIENTE_TITULAR"
                                                        />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-xs-12">
                                        <div className="card">
                                            <div className={`card-status ${CLIENTE()}`}></div>
                                            <div className="card-header">
                                                <h3 className="card-title">Fiador</h3>
                                                <div className="card-options">
                                                    {
                                                        buttonsFiador[0]
                                                    }
                                                </div>
                                            </div>
                                            <div style={{ height: 180, maxHeight: 180 }} >
                                                {
                                                    list !== undefined ?
                                                        <TableVirtualized
                                                            tableColumns={tableColumnsReferenciasCliente}
                                                            ids={idsFiador}
                                                            list={listFiador}
                                                            keyVal="id"
                                                            actionSelect={selectAction}
                                                            actionClick={this.actionClick}
                                                            selected={selectedFiador}
                                                            tipo="CLIENTE_FIADOR"
                                                        />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    <div className="card-footer text-right">
                        {
                            user.editar ?
                                <button type="button" className={`btn btn-icon ${CLIENTE()}`} onClick={() => saveAction()} >
                                    <i className="fa fa-save"></i> {edit ? "Actualizar" : "Guardar"}
                                </button> : null
                        }
                    </div>
                </div>


            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectRow: state.clientes.get('selectRow'),
        edit: state.clientes.get('edit'),
        selectedFiador: state.clientes.get('selectedFiador'),
        selectedTitular: state.clientes.get('selectedTitular'),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectAction: (id, reloadGrid, tipo) => dispatch(selectAction(id, reloadGrid, tipo)),
        toggleModal: () => dispatch(toggleModal()),
        editRow: (tipo, id) => dispatch(editRow(tipo, id)),
        changeAttr: (tipo, attr, value) => dispatch(changeAttr(tipo, attr, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddClientes)