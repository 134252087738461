import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import moment from 'moment'
import numeral from 'numeral'
import Pagination from 'react-js-pagination'
import FontAwesome from 'react-fontawesome'
import { DataGrid } from '@material-ui/data-grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

//UI
import BoxButton from '../../components/Common/BoxButtonV2'
import Permisos from '../../components/Common/Permisos'
import ReactDataGridFilter from '../../components/Cobros/ReactDataGridFilter'

import { CLIENTE } from '../../actions/index'
import { getFlujoUtilidades, saveAction } from '../../actions/flujoUtilidades'
import { selectAction, changeAttr, newRow, changePage } from '../../actions/common'
import { GetPermisosVistas } from '../../utils/helpers'

class FlujoUtilidades extends Component {

    constructor(props) {
        super(props);
        this.changePage = this.changePage.bind(this);

        this.state = {
            gridHeight: 300,
            _permisos: false
        }
    }

    componentWillMount() {
        this.setState({ _permisos: GetPermisosVistas(this.props.user, "Flujo de utilidades") });
    }

    componentDidMount() {
        if (this.state._permisos.ver) {
            this.props.getFlujoUtilidades();
        }
        var node = ReactDOM.findDOMNode(this.refs["dataExport"]);
        var gridHeight = node.clientHeight;
        this.setState({ gridHeight: gridHeight - 48 });
    }

    changePage(page) {
        this.props.getFlujoUtilidades(page);
        this.props.changePage("FLUJO_UTILIDADES", page)
    }

    render() {
        const { ids, list, selectRow, changeAttr, total, pageNumber, countRows, per_page } = this.props;
        var today = moment((new Date())).format('YYYY-MM-DD');

        let buttons = []
        if (this.state._permisos.editar) {
            buttons = [
                <BoxButton key="bfc[0][0]" name="plus" onClick={() => this.props.newRow(tipo)} title="Agregar nuevo" classCSS="info" />,
                <BoxButton key="bfc[0][1]" name="save" onClick={() => this.props.saveAction()} title="Guardar" classCSS="success" />,
            ]
        }

        const tipo = "FLUJO_UTILIDADES";

        if (!this.state._permisos.ver) {
            return (
                <Permisos ref="dataExport" />
            )
        } else {
            return (
                <div className="card shadow border-left-success mb-4">
                    <div className={`card-status card-status-left ${CLIENTE()}`}></div>
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h4 className={`m-0 ${CLIENTE(1)}`}>Flujo de utilidades</h4>
                        <div className="card-options">
                            {
                                buttons.map(item => {
                                    return (item)
                                })
                            }
                        </div>
                    </div>
                    <div style={{ minHeight: 'calc(100vh - 240px)' }} ref="dataExport">
                        <div className="row col-md-12" >
                            <div className="col-md-4" style={{ padding: 0, borderRight: "1px solid rgba(0, 40, 100, 0.12)" }}>
                                <div className="col-md-12  card-header py-3 d-flex float-right">
                                    Utilidad neta: <h5 className={`m-1 font-weight-bold ${CLIENTE(1)}`}>
                                        {numeral(total).format()}
                                    </h5>
                                </div>
                                <div className={`${selectRow !== null ? "" : "disabledDiv"} pb-5`}>
                                    <div className="col-md-12">
                                        <TextField id="fecha" label="Fecha" size="small" fullWidth type="date" margin="normal" value={selectRow !== null ? moment(selectRow.get('Fecha')).format('YYYY-MM-DD') : ''} onChange={(e) => changeAttr(tipo, 'Fecha', e.target.value)} />
                                    </div>
                                    <div className="col-md-12">
                                        <TextField id="descripcion" label="Descripción" size="small" multiline fullWidth rowsMax={4} margin="normal" value={selectRow !== null ? selectRow.get('Descripcion') : ''} onChange={(e) => changeAttr(tipo, 'Descripcion', e.target.value)} />
                                    </div>
                                    <div className="col-md-12">
                                        <FormControl fullWidth size="small" margin="normal">
                                            <InputLabel htmlFor="tipo">Tipo</InputLabel>
                                            <Select
                                                value={selectRow !== null ? selectRow.get('Tipo') : ''}
                                                onChange={(e) => changeAttr(tipo, 'Tipo', e.target.value)}
                                                inputProps={{
                                                    name: 'Tipo',
                                                    id: 'tipo',
                                                }}
                                            >
                                                <option value="1">Entrada</option>
                                                <option value="2">Salida</option>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-12">
                                        <TextField id="valor" label="Valor" size="small" fullWidth type="number" margin="normal" value={selectRow !== null ? selectRow.get('Valor') : ''} onChange={(e) => changeAttr(tipo, 'Valor', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8" style={{ paddingLeft: 25 }} >
                                {
                                    list.size !== 0 ?
                                        <ReactDataGridFilter
                                            rows={list}
                                            height={this.state.gridHeight}
                                            ids={ids}
                                        /> : null
                                }

                                <div className="row justify-content-end">
                                    <div className="col-md-4">
                                        <div style={{ margin: 7 }}>
                                            Total registros {countRows}
                                        </div>
                                    </div>
                                    <div className="col-md-8" >
                                        <div style={{ margin: 7 }}>
                                            <Pagination
                                                activePage={pageNumber}
                                                itemsCountPerPage={per_page}
                                                totalItemsCount={countRows}
                                                pageRangeDisplayed={10}
                                                onChange={n => this.changePage(n)}
                                                itemClass="page-item"
                                                innerClass="pagination pagination-sm justify-content-end"
                                                linkClass="page-link"
                                                firstPageText={<FontAwesome name="angle-double-left" />}
                                                prevPageText={<FontAwesome name="angle-left" />}
                                                nextPageText={<FontAwesome name="angle-right" />}
                                                lastPageText={<FontAwesome name="angle-double-right" />}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        list: state.flujoUtilidades.get('list'),
        selected: state.flujoUtilidades.get('selected'),
        ids: state.flujoUtilidades.get('ids'),
        selectRow: state.flujoUtilidades.get('selectRow'),
        total: state.flujoUtilidades.get('total'),
        pageNumber: state.flujoUtilidades.get('pageNumber'),
        countRows: state.flujoUtilidades.get('countRows'),
        per_page: state.flujoUtilidades.get('per_page'),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFlujoUtilidades: (page) => dispatch(getFlujoUtilidades(page)),
        saveAction: () => dispatch(saveAction()),
        selectAction: (id, reloadGrid, tipo) => dispatch(selectAction(id, reloadGrid, tipo)),
        changeAttr: (tipo, attr, value) => dispatch(changeAttr(tipo, attr, value)),
        newRow: (tipo) => dispatch(newRow(tipo)),
        changePage: (tipo, page) => dispatch(changePage(tipo, page))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlujoUtilidades)