import React, { Component } from 'react'
import { CLIENTE } from '../../../actions/index'

// UI
import DataGrid from './DataGrid'

export default class ListaDetalles extends Component {

    render() {
        const { list, ids, editable } = this.props;
        return (
            <div style={{ marginLeft: 2 }}>
                {
                    editable ?
                        <DataGrid
                            rows={list}
                            ids={ids}
                            changeAction={this.props.changeAction}
                        />
                        :
                        <table className="table card-table">
                            <tbody>
                                {
                                    list !== null ? list.valueSeq().map((item, index) => {
                                        return (
                                            <tr key={item.get('id')}>
                                                <td width="1">
                                                    <span className={`badge ${CLIENTE()} badge-pill`}>{item.get('id_interno')}</span>
                                                </td>
                                                <td className="text-right">
                                                    {item.get('valor')}
                                                </td>
                                            </tr>
                                        )
                                    }) : null
                                }
                            </tbody>
                        </table>
                }
            </div>
        )
    }
}