import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Line, Bar, Radar } from 'react-chartjs-2';

import { getNewClientes } from '../actions/dashboard'

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                labels: this.props.label1,
                datasets: [
                    {
                        label: 'Nuevos',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: 'red',
                        borderColor: 'red',
                        borderCapStyle: 'butt',
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: this.props.dataSET_N
                    }
                ]
            },
            fullScreen: false,
            chartType: 1
        }
    }

    componentWillMount() {
        this.props.getNewClientes();
        const data = this.state.data;
        data.labels = this.props.label1;
        data.datasets[0].data = this.props.dataSET_N;
        this.setState({ data: data })
    }

    fullScreen() {
        this.setState({ fullScreen: !this.state.fullScreen })
    }

    renderChartType(data) {
        switch (this.state.chartType) {
            case 1:
                return (
                    <Line ref="chart" data={data}
                        options={{ maintainAspectRatio: false }}
                    />
                )
            case 2:
                return (
                    <Bar ref="chart" data={data}
                        options={{ maintainAspectRatio: false }}
                    />
                )
            case 3:
                return (
                    <Radar ref="chart" data={data}
                        options={{ maintainAspectRatio: false }}
                    />
                )
            default:
                break;
        }
    }

    render() {

        const { cantNew, cantRen, label, dataSET_N, dataSET_R } = this.props;
        const data = {
            labels: label,
            datasets: [
                {
                    label: 'Nuevos',

                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: '#467fcf',
                    borderColor: '#467fcf',
                    borderCapStyle: 'butt',
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: dataSET_N
                },
                {
                    label: 'Renovados',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: '#5eba00 ',
                    borderColor: '#5eba00 ',
                    borderCapStyle: 'butt',
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: dataSET_R
                }
            ]
        };

        return (
            <div className="row col-12">
                {
                    !this.state.fullScreen ?
                        <div className={`${this.state.fullScreen ? "col-lg-3" : ""}`}>
                            <div className="col-sm-12 col-lg-12">
                                <div className="card p-2">
                                    <div className="d-flex align-items-center">
                                        <span className="stamp stamp-md bg-blue mr-3">
                                            <i className="fas fa-clipboard-list"></i>
                                        </span>
                                        <div>
                                            <h4 className="m-0">Clientes nuevos</h4>
                                            <small className="text-muted">{cantNew}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-12">
                                <div className="card p-2">
                                    <div className="d-flex align-items-center">
                                        <span className="stamp stamp-md bg-green mr-3">
                                            <i className="fas fa-retweet"></i>
                                        </span>
                                        <div>
                                            <h4 className="m-0">Clientes renovados</h4>
                                            <small className="text-muted">{cantRen}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-12">
                                <div className="custom-controls-stacked">
                                    <label className="custom-control custom-radio">
                                        <input type="radio" className="custom-control-input" name="example-radios" value="option1" checked={this.state.chartType == 1 ? true : false} onChange={(e) => this.setState({ chartType: 1 })} />
                                        <div className="custom-control-label">Lineal</div>
                                    </label>
                                    <label className="custom-control custom-radio">
                                        <input type="radio" className="custom-control-input" name="example-radios" value="option2" checked={this.state.chartType == 2 ? true : false} onChange={(e) => this.setState({ chartType: 2 })} />
                                        <div className="custom-control-label">Barras</div>
                                    </label>
                                    <label className="custom-control custom-radio">
                                        <input type="radio" className="custom-control-input" name="example-radios" value="option2" checked={this.state.chartType == 3 ? true : false} onChange={(e) => this.setState({ chartType: 3 })} />
                                        <div className="custom-control-label">Radar</div>
                                    </label>
                                </div>
                            </div>
                        </div> : null
                }

                <div className={`${this.state.fullScreen ? "col-lg-12" : "col-lg-9"}`}>
                    <div className="card" style={{ height: "calc(100vh - 120px)", maxHeight: "calc(100vh - 120px)", marginBottom: 0 }} >
                        <div className="card-header">
                            <div className="card-options">
                                <a href="#" className="card-options-fullscreen" data-toggle="card-fullscreen" onClick={() => this.fullScreen()}>
                                    {
                                        this.state.fullScreen ? <i className="fe fe-minimize"></i> : <i className="fe fe-maximize"></i>
                                    }
                                </a>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '100vh' }}>
                            {
                                this.renderChartType(data)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        clientesNew: state.dashboard.get('clientesNew'),
        cantNew: state.dashboard.get('cantNew'),
        cantRen: state.dashboard.get('cantRen'),
        label: state.dashboard.get('label'),
        dataSET_N: state.dashboard.get('data_N'),
        dataSET_R: state.dashboard.get('data_R')
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNewClientes: () => dispatch(getNewClientes()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)