import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

//UI
import Loading from '../components/Common/Loading'

//Acciones
import { toggleModal } from '../actions/common'
import { CLIENTE, clearMessage } from '../actions/index'
import { signOut } from '../actions/auth'


class Layout extends Component {
  constructor(props) {

    super(props);
    this.state = {
      isOpen: true,
      sidebarItems: [
        {
          icon: 'cog', caption: 'Administración', isActive: false, isOpen: false,
          subitems: [
            { caption: 'Clientes', isActive: false, title: 'Clientes', url: '/administracion/clientes', icon: 'user' },
            { caption: 'Usuarios', isActive: false, title: 'Usuarios', url: '/administracion/usuarios', icon: 'user-friends' },
            { caption: 'Maestras', isActive: false, title: 'Maestras', url: '/administracion/maestras', icon: 'list-ol' },
            { caption: 'Roles', isActive: false, title: 'Maestras', url: '/administracion/roles', icon: 'user-cog' }
          ]
        },
        {
          icon: 'credit-card', caption: 'Cobros', isActive: false, isOpen: false,
          subitems: [
            { caption: 'Rutas', isActive: false, title: 'Rutas', url: '/cobros/rutas', icon: 'route' },
            { caption: 'Flujo de caja', isActive: false, title: 'Flujo de caja', url: '/cobros/flujoCaja', icon: 'hand-holding-usd' },
            { caption: 'Flujo de utilidades', isActive: false, title: 'Flujo de utilidades', url: '/cobros/flujoUtilidades', icon: 'funnel-dollar' },
          ]
        },
        {
          icon: 'chart-line', caption: 'Reportes', isActive: false, isOpen: false,
          subitems: [
            { caption: 'Coteos', isActive: false, title: 'Coteos', url: '/reportes/coteos', icon: 'file-invoice-dollar' }
          ]
        },
        // {
        //   icon: 'chart-line', caption: 'Nómina', isActive: false, isOpen: false,
        //   subitems: [
        //     { caption: 'Primer Pantalla', isActive: false, title: 'Coteos', url: '/reportes/np1', icon: 'file-invoice-dollar' },
        //     { caption: 'Segunda Pantalla', isActive: false, title: 'Coteos', url: '/reportes/np2', icon: 'file-invoice-dollar' }
        //   ]
        // }
      ]
    };
  }

  componentDidUpdate() {
    this.props.clearMessage()
  }

  chargeRol() {
    if (this.props.user) {
      switch (this.props.user.Rol) {
        case 1:
          return ("Administrador");
        case 2:
          return ("Supervisor");
        case 3:
          return ("Empleado");
        default:
          break;
      }
    }
  }

  render() {

    if (this.props.messages) {
      const { messages } = this.props
      switch (messages.type) {
        case 'danger':
          toast.error(messages.message)
          break
        case 'warning':
          toast.warn(messages.message)
          break
        case 'success':
          toast.success(messages.message)
          break
        default:
          toast.info(messages.message)
      }
    }
    
    const { user, signOut, pageName } = this.props;

    return (
      <div className="page">
        <div className="page-main">
          <nav className={`navbar navbar-expand-lg navbar-light ${CLIENTE()}`} style={{ minHeight: 65 }}>
            <div className="container">
              <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                <div className="sidebar-brand-icon rotate-n-15">
                  <i className="fab fa-earlybirds"></i>
                </div>
                <div className="sidebar-brand-text mx-3"> {pageName} </div>
              </a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto topnav">
                  {/* <li className="nav-item">
                    <a href="/" className="nav-link"><i className="fe fe-home" style={{ marginRight: 5 }}></i> Inicio</a>
                  </li> */}
                  {
                    this.state.sidebarItems.map((item, index) => {
                      return (
                        <li className="nav-item dropdown" key={`[${index}}]`} >
                          <Link className="nav-link dropdown-toggle" data-toggle="dropdown" to="#" style={{ marginTop: 5 }}>
                            <i className={`fas fa-fw fa-${item.icon}`} style={{ marginRight: 5 }} ></i> {item.caption}
                          </Link>
                          <div className="dropdown-menu">
                            {
                              item.subitems.map((subItem, indexI) => {
                                return (
                                  <Link key={`[${index}][${indexI}}]`} className={`dropdown-item`} to={subItem.url}>
                                    <i className={`fas fa-fw fa-${subItem.icon}`} style={{ marginRight: 5 }} ></i>{subItem.caption}
                                  </Link>
                                )
                              })
                            }
                          </div>
                        </li>
                      )
                    })
                  }
                  <li>
                    <div className="divider-menu"></div>
                  </li>
                  <li className="nav-item">
                    <div className="dropdown">
                      <a href="#" className="nav-link pr-0 leading-none" data-toggle="dropdown">
                        <span className="avatar avatar-azure">{user ? user.Nombres.substr(0, 1) + user.Apellidos.substr(0, 1) : ''}</span>
                        <span className="ml-2 d-none d-lg-block">
                          <span className="text-white">{user ? user.Nombres + ' ' + user.Apellidos : ''} </span>
                          <small className="text-default d-block mt-1">
                            {
                              this.chargeRol()
                            }
                          </small>
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <Link className={`dropdown-item`} to="/perfil">
                          <i className="dropdown-icon fe fe-user"></i> Mi Perfil
                        </Link>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" onClick={() => signOut()} >
                          <i className="dropdown-icon fe fe-log-out"></i> Cerrar sesión
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {/* Main */}
          <div className="my-3 my-md-5">
            <div className="container">
              {
                this.props.children
              }
            </div>
          </div>
        </div>
        <Loading />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    rol: state.auth.rol,
    pageName: state.auth.pageName,
    messages: state.messages.get('message'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleModal: () => dispatch(toggleModal()),
    clearMessage: () => dispatch(clearMessage()),
    signOut: () => dispatch(signOut()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)

