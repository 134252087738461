import * as types from './types'
import {
    createAxiosInstance
} from '../utils/helpers'
import {
    setLoading
} from './common'

import {
    API_URL,
    messageHandler
} from './index'
import objectifyArray from 'objectify-array'

const axios = createAxiosInstance();

export function getClientes() {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(`${API_URL}/clientes`, {})
            .then((res) => {
                const data = objectifyArray(res.data.data, {
                    by: ['id'],
                    recursive: true
                })
                dispatch(setLoading(false));
                dispatch({
                    type: types.GET_CLIENTES,
                    payload: {
                        data,
                        creditosActivos: res.data.creditosActivos
                    }
                })
            })
            .catch((err) => {
                dispatch(setLoading(false));
                messageHandler(dispatch, err)
            })
    }
}

export function getDetallesClientes(id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(`${API_URL}/clientes/` + id, {})
            .then((res) => {
                console.log(res)
                const data = objectifyArray(res.data, {
                    by: ['Id'],
                    recursive: true
                })
                dispatch(setLoading(false));
                dispatch({
                    type: types.SET_DETALLES_CLIENTES,
                    payload: {
                        data,
                        id: id
                    }
                })
            })
            .catch((err) => {
                dispatch(setLoading(false));
                messageHandler(dispatch, err)
            })
    }
}

export function saveAction() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        const row = getState().clientes.get('selectRow').toJS()
        let cr = []
        for (let index = 0; index < row.clientes_referencias.length; index++) {
            const x = row.clientes_referencias[index];
            cr.push({
                id: x.id ? x.id : 0,
                cliente_id: row.id,
                nombre: x.nombre,
                direccion: x.direccion,
                barrio: x.barrio,
                telefono: x.telefono,
                parentesco: x.parentesco,
                new: x.new ? x.new : false,
                tipo_referencia : x.tipo_referencia
            })
        }
        row.clientes_referencias = cr;
        const newRow = getState().clientes.get('edit')
        if (!newRow) {
            axios.post(`${API_URL}/clientes`, row)
                .then((res) => {
                    const data = objectifyArray(res.data.data, {
                        by: ['id'],
                        recursive: true
                    })
                    dispatch({
                        type: types.GET_CLIENTES,
                        payload: {
                            data,
                            creditosActivos: res.data.creditosActivos
                        }
                    })
                    dispatch({
                        type: types.CLEAN_CLIENTE
                    })
                    dispatch({
                        type: types.TOOGLE_SIDEBAR_RIGTH
                    })
                    dispatch(setLoading(false));
                    messageHandler(dispatch, {
                        success: 'Se ha agregado un nuevo registro'
                    })
                })
                .catch(err => {
                    dispatch(setLoading(false));
                    messageHandler(dispatch, err)
                })
        } else {
            axios.put(`${API_URL}/clientes`, row)
                .then((res) => {
                    const data = objectifyArray(res.data.data, {
                        by: ['id'],
                        recursive: true
                    })
                    dispatch({
                        type: types.GET_CLIENTES,
                        payload: {
                            data,
                            creditosActivos: res.data.creditosActivos
                        }
                    })
                    dispatch({
                        type: types.CLEAN_CLIENTE
                    })
                    dispatch({
                        type: types.TOOGLE_SIDEBAR_RIGTH
                    })
                    dispatch(setLoading(false));
                    messageHandler(dispatch, {
                        success: 'Se ha actualizado el registro'
                    })
                })
                .catch(err => {
                    dispatch(setLoading(false));
                    messageHandler(dispatch, err)
                })
        }

    }
}

export function changeState(id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(`${API_URL}/clientes/${id}`)
            .then((res) => {
                dispatch(setLoading(false));
                const data = objectifyArray(res.data.data, {
                    by: ['id'],
                    recursive: true
                })
                dispatch({
                    type: types.GET_CLIENTES,
                    payload: {
                        data,
                        creditosActivos: res.data.creditosActivos
                    }
                })

                messageHandler(dispatch, {
                    success: 'Se ha cambiado el estado al cliente'
                })
            })
            .catch(err => {
                dispatch(setLoading(false));
                messageHandler(dispatch, err)
            })
    }
}

export function saveActionReferencias(tipo) {
    return (dispatch) => {
        dispatch({
            type: types.ADD_REFERENCIA,
            payload: tipo
        })
    }
}

export function editCliente(id) {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_CLIENTE,
            payload: {
                id
            }
        })
    }
}

export function cleanCliente() {
    return (dispatch) => {
        dispatch({
            type: types.CLEAN_CLIENTE
        })
    }
}