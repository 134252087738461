import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import moment from 'moment'
import numeral from 'numeral'
import Swal from 'sweetalert2'
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

//UI
import BoxButton from '../../components/Common/BoxButtonV2'
import Modal from '../../components/Common/Modal'
import DataGrid from '../../components/Common/DataGrid'
import Permisos from '../../components/Common/Permisos'
import Enrutar from '../../components/Cobros/Rutas/Enrutar'
import AddCredito from '../../components/Cobros/Rutas/AddCredito'
import RenovarCredito from '../../components/Cobros/Rutas/RenovarCredito'
import ObservacionesCredito from '../../components/Cobros/Rutas/ObservacionesCredito'
import DetallesPagos from '../../components/Cobros/Rutas/DetallesPagos'
import DetalleRenovaciones from '../../components/Cobros/Rutas/DetalleRenovaciones'
import AddClientes from '../../components/Administracion/Clientes/AddClientes'
import ModalFilterMaestras from '../../components/Common/ModalFilterMaestras'


import { getCreditos, saveCredito, getListRutas, getListPeriodos, saveAbonos, saveRenovacion, saveRenovacion1, cleanDataRutas, reorderData, saveRenovacionInmediata, deleteRenovacion, deleteCredito, clearNewClientesCreditos } from '../../actions/rutas'
import { cleanCliente, saveAction } from '../../actions/clientes'
import { selectAction, changeAttr2, toggleModal, newRow, toogleSidebar1 } from '../../actions/common'
import { exportDataGrid, GetPermisosVistas } from '../../utils/helpers'
import { showHideModalFilter } from "../../actions/filtrarData";
import { CLIENTE } from "../../actions/index";

const tipo = "RUTA";

class Rutas extends Component {
    constructor(props) {
        super(props);
        this.changeAction = this.changeAction.bind(this);
        this.actionClick = this.actionClick.bind(this);
        this.actionClickRenovados = this.actionClickRenovados.bind(this);
        this.actionClickDias = this.actionClickDias.bind(this);
        this.actionClickCancelarRenovado = this.actionClickCancelarRenovado.bind(this);
        this.actionClickRenovadosInmediatos = this.actionClickRenovadosInmediatos.bind(this);
        this.actionClickEliminarCredito = this.actionClickEliminarCredito.bind(this);
        this.createAction = this.createAction.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.actionToogleSidebarRigth = this.actionToogleSidebarRigth.bind(this);
        this.actionToogleSidebarRigth1 = this.actionToogleSidebarRigth1.bind(this);
        this.getCoteoCuota = this.getCoteoCuota.bind(this);
        this.onExportDataGrid = this.onExportDataGrid.bind(this);
        this.hideMainMenu = this.hideMainMenu.bind(this);
        this.saveAction = this.saveAction.bind(this);
        this.toggleReferencia = this.toggleReferencia.bind(this);

        this.state = {
            showMainMenu: true,
            tipoModal: 0,
            tabs: [
                { id: 0, caption: 'Observaciones', component: <ObservacionesCredito />, active: true },
                { id: 1, caption: 'Abonos', component: <DetallesPagos />, active: false },
                { id: 2, caption: 'Renovaciones', component: <DetalleRenovaciones />, active: false },
            ],
            tab: 0,
            toogleSidebarRigth: false,
            idRenovar: null,
            gridHeight: 350,
            sideBarHeight: "80vh",
            moras: false,
            fechaExporte: moment().add(1, 'days'),
            value_dias: "",
            _permisos: false,
            tipoRef: ''
        }

    }

    componentWillMount() {
        this.setState({ _permisos: GetPermisosVistas(this.props.user, "Rutas") });
    }

    componentDidMount() {
        if (this.state._permisos.ver) {
            this.props.cleanDataRutas();
            this.props.getListRutas();
            this.props.getListPeriodos();
            this.props.cleanCliente();
        }
        var node = ReactDOM.findDOMNode(this.refs["dataExport"]);
        var gridHeight = node.clientHeight;
        this.setState({ gridHeight: gridHeight });
    }

    showPermiso() {
        Swal.fire({
            title: 'Opssss....',
            html: `<div> 
                <p> No tiene permisos, para realizar esta acción </p>
               </div>`,
        })
    }

    toggleReferencia(tipo) {
        this.setState({ tipoRef: tipo })
        this.props.newRow("REFERENCIA");
        this.setState({ tipoModal: 2 })
        this.props.toggleModal();
    }

    onExportDataGrid() {
        this.setState({ tipoModal: 4 })
        this.props.toggleModal();
    }

    saveAction() {
        this.props.saveAction();
    }

    createAction() {
        this.setState({ tipoModal: 0 })
        this.props.newRow("RUTA");
        this.props.toggleModal();
        this.props.clearNewClientesCreditos();
    }

    changeAction(tipo, id, attr, value, toogle = false) {
        this.props.changeAttr2(tipo, id, attr, value)
        if (toogle)
            this.props.toggleModal()
    }

    actionClick(id) {
        this.props.selectAction(id, null, tipo);
        this.setState({ tipoModal: 1 })
        this.props.toggleModal();
    }

    actionClickRenovados(rowId) {
        if (this.state._permisos.editar) {
            this.setState({ idRenovar: rowId })
            this.setState({ tipoModal: 2 });
            this.props.saveRenovacion(rowId);
        } else {
            this.showPermiso();
        }
    }

    actionClickDias(rowId) {
        this.setState({ idRenovar: rowId })
        this.setState({ tipoModal: 5 });
        this.props.toggleModal();
    }

    actionClickRenovadosInmediatos(rowId) {
        if (this.state._permisos.editar) {
            Swal.fire({
                title: 'Renovar credito',
                html: `<div> 
                    <p> Realmente desea renovar este credito? </p>
                   </div>`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar'
            }).then((result) => {
                if (result.value) {
                    this.setState({ idRenovar: rowId })
                    this.props.saveRenovacionInmediata(rowId);
                }
            })
        } else {
            this.showPermiso();
        }
    }

    actionClickEliminarCredito(rowId) {
        if (this.state._permisos.editar) {
            Swal.fire({
                title: 'Retirar credito',
                html: `<div> 
                    <p> Realmente desea retirar este credito de la ruta? </p>
                   </div>`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar cambios'
            }).then((result) => {
                if (result.value) {
                    this.setState({ idRenovar: rowId })
                    this.props.deleteCredito(rowId);
                }
            })
        } else {
            this.showPermiso();
        }
    }

    actionClickCancelarRenovado(rowId) {
        if (this.state._permisos.editar) {
            Swal.fire({
                title: 'Cancelar renovación',
                html: `<div> 
                        <p> Realmente desea devolver el estado de la renovación? </p>
                       </div>`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar cambios'
            }).then((result) => {
                if (result.value) {
                    this.props.deleteRenovacion(rowId);
                }
            });
        } else {
            this.showPermiso();
        }

    }

    actionClickReorder() {
        this.setState({ tipoModal: 3 })
        this.props.toggleModal();
    }

    saveRenovacion() {
        this.props.saveRenovacion(this.state.idRenovar)
    }

    saveRenovacion1() {
        this.props.saveRenovacion1(this.state.idRenovar)
    }

    onChangeSelect(id) {
        this.props.getCreditos(id);
        this.hideMainMenu();
    }

    changeTab(tab) {
        let tabs = this.state.tabs.map(x => {
            x.active = false;
            return x;
        })
        tabs[tabs.findIndex(x => x.id === tab.id)].active = true;
        this.setState({ tabs, tab: tab.id });
    }

    actionToogleSidebarRigth(tipo = null) {
        this.setState({ tipoModal: tipo });
        this.setState({ toogleSidebarRigth: !this.state.toogleSidebarRigth })
        this.props.toggleModal();
    }


    actionToogleSidebarRigth1() {
        this.props.toogleSidebar1();
    }

    reorderData(data) {
        this.props.reorderData();
        this.actionToogleSidebarRigth1()
    }

    getCoteoCuota(x) {
        let coteo = 0
        const rest = (x.get('cuota') * 1000) / x.get('mod_cuota')
        if (x.get('cuota') >= 5) {
            if ((x.get('cuota') * 1000) < x.get('mod_cuota')) {
                coteo = 1;
            }
            else {
                coteo = Math.floor(rest);
            }
        } else {
            coteo = 0;
        }
        return coteo;
    }

    saveAbonos() {
        let entrada = 0, salida = 0, utilidad = 0, coteos = 0;
        this.props.list.map((x) => {
            entrada = entrada + Number(x.get('cuota'));
            if (x.get('renovacion')) {
                coteos++;
                salida = salida + Number(x.getIn(['renovacion', 'monto']));
                if (x.getIn(['renovacion', 'editable'])) {
                    utilidad = utilidad + (x.getIn(['renovacion', 'utilidad']) * 1000);
                } else {
                    utilidad = utilidad + (x.get('valorTotal') - x.get('valor_prestamo'));
                }
            } else {
                coteos = coteos + this.getCoteoCuota(x);
            }
        })

        const _new = this.props.nuevos;

        entrada = entrada * 1000;
        salida = salida * 1000;

        if (_new > 0) {
            salida = salida + _new
        }

        Swal.mixin({
            confirmButtonText: 'Siguiente',
            showCancelButton: true,
            progressSteps: ['1', '2'],
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).queue([
            {
                title: 'FLUJO DE CAJA',
                html: `<div> 
                    <p> Entran: ${numeral(entrada).format('')} </p>
                    <p> Salen: ${numeral(salida).format('')} </p>
                    <p> Utilidad: ${numeral(utilidad).format('')} </p>
                    <p> Coteos: ${coteos} </p>
                    </div>`,
            },
            {
                title: "Calculo de moras",
                input: 'checkbox',
                inputValue: 1,
                inputPlaceholder: 'Desea calcular moras?',
                confirmButtonText: 'Confirmar cambios'
            }
        ]).then((result) => {
            if (result.value) {
                if (result.value[0]) {
                    this.props.saveAbonos(entrada, salida, utilidad, coteos, result.value[1] === 1 ? true : false)
                }
            }
        })
    }

    renderSwitch(param) {
        let buttons = []

        const { tabs, tab } = this.state;

        switch (param) {
            case 0:
                if (this.state._permisos.editar) {
                    buttons = [
                        <BoxButton key="b1[0][0]" name="save" onClick={() => this.props.saveCredito()} title="Guardar crédito" classCSS="info" disabled={this.props.rows.toJS().length > 0 ? false : true} />,
                    ]
                }
                return (
                    <Modal title="Gestionar créditos" buttons={buttons} brand={true} width={900} className="modal-lg">
                        <AddCredito action={this.actionToogleSidebarRigth} obsDias={this.props.obsDias} />
                    </Modal>
                )
            case 1:
                return (
                    <Modal title="Detalles de abono al credito" brand={false} hideBody={true}  >
                        <div style={{ marginLeft: -2 }} >
                            {/* <div className={`card-status card-status-top ${CLIENTE()}`}></div> */}
                            <nav >
                                <div className="nav nav-tabs nav-fill nav-tabs-component" id="nav-tab" role="tablist">
                                    {
                                        tabs.map(x => {
                                            return (
                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                <a
                                                    key={`li01[${x.id}]`}
                                                    className={x.active ? `nav-item nav-link active ${CLIENTE(2)}` : "nav-item  nav-link"}
                                                    role="tab"
                                                    aria-expanded={x.active ? "true" : "false"}
                                                    onClick={(e) => this.changeTab(x)}
                                                >
                                                    {x.caption}
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                            </nav>
                            <div className="tab-content">
                                <div className="tab-pane fade show active table-responsive" style={{ height: 'calc(100vh - 230px)' }} onClick={() => this.sav}>
                                    {tabs[tab].component}
                                </div>
                            </div>
                        </div>
                    </Modal>
                )
            case 2:
                if (this.state._permisos.editar) {
                    buttons = [
                        <BoxButton key="b2[0][0]" name="save" onClick={() => this.saveRenovacion1()} title="Guardar crédito" classCSS="info" />
                    ]
                }
                return (
                    <Modal title="Renovar crédito" buttons={buttons} brand={true} >
                        <RenovarCredito id={this.state.idRenovar} />
                    </Modal>
                )
            case 4:
                if (this.state._permisos.editar) {
                    buttons = [
                        <BoxButton key="b1[0][0]" name="save" onClick={() => exportDataGrid(this.props.list, this.props.idRuta, this.props.cobrador, this.state.fechaExporte, this.props.cartera)} title="Guardar exporte" classCSS="info" />
                    ]
                }
                return (
                    <Modal title="Exportar ruta" buttons={buttons} brand={true}>
                        <div className="row" style={{ padding: '10px 0' }}>
                            <div className="col-md-7">La fecha de exporte es automática, si desea cambiarla por favor seleccione...</div>
                            <div className="col-md-5">
                                <input className="form-control form-control-sm" type="date" style={{ marginTop: 15 }} value={moment(this.state.fechaExporte).format('YYYY-MM-DD')} onChange={(e) => this.setState({ fechaExporte: e.target.value })} />
                            </div>
                        </div>
                    </Modal>
                )
            case 5:
                if (this.state._permisos.editar) {
                    buttons = [
                        <BoxButton key="b1[0][0]" name="save" onClick={() => this.changeAction("RUTA", this.state.idRenovar, "obs_dia", this.state.value_dias, true)} title="Guardar exporte" classCSS="info" />,
                    ]
                }
                return (
                    <Modal title="Cambiar día observación" buttons={buttons} brand={true}>
                        <div className="row" style={{ padding: 20 }}>
                            <div className="col">Observación día:</div>
                            <div className="col">
                                <select className="form-control form-control-sm" id="observacion" onChange={(e) => this.setState({ value_dias: e.target.value })} >
                                    <option value="" key={0}>Seleccione..</option>
                                    {
                                        this.props.obsDias.map((x, i) => {
                                            return (
                                                <option value={x.get("label")} key={i}>{x.get("label")}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </Modal>
                )
            case 6:
                const buttonsModal = [
                    <BoxButton key="bb[2][0]" name="save" onClick={() => alert("Hola")} title="Agregar referencia" classCSS="info" />,
                ]
                return (
                    <Modal title={"Agregar referencia " + this.state.tipoRef} buttons={buttonsModal} brand={true} >
                        <div className="row">
                            <div className="col-md-6">
                                <TextField
                                    error={this.props.selectRowReferencia !== undefined ? this.props.selectRowReferencia.get('nombre') ? false : true : null} margin="normal"
                                    id="nombre" label="Nombre" size="small" fullWidth
                                    value={this.props.selectRowReferencia === undefined || this.props.selectRowReferencia === null ? '' : this.props.selectRowReferencia.get('nombre')}
                                    onChange={(e) => this.props.changeAttr("REFERENCIA", 'nombre', e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    error={this.props.selectRowReferencia !== undefined ? this.props.selectRowReferencia.get('direccion') ? false : true : null} margin="normal"
                                    id="direccion" label="Dirección" size="small" fullWidth
                                    value={this.props.selectRowReferencia === undefined || this.props.selectRowReferencia === null ? '' : this.props.selectRowReferencia.get('direccion')}
                                    onChange={(e) => this.props.changeAttr("REFERENCIA", 'direccion', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={this.props.selectRowReferencia !== undefined ? this.props.selectRowReferencia.get('barrio') ? false : true : null} margin="normal"
                                    id="barrio" label="Barrio" size="small" fullWidth
                                    value={this.props.selectRowReferencia === undefined || this.props.selectRowReferencia === null ? '' : this.props.selectRowReferencia.get('barrio')}
                                    onChange={(e) => this.props.changeAttr("REFERENCIA", 'barrio', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={this.props.selectRowReferencia !== undefined ? this.props.selectRowReferencia.get('telefono') ? false : true : null} margin="normal"
                                    id="telefono" label="Teléfono" size="small" fullWidth
                                    value={this.props.selectRowReferencia === undefined || this.props.selectRowReferencia === null ? '' : this.props.selectRowReferencia.get('telefono')}
                                    onChange={(e) => this.props.changeAttr("REFERENCIA", 'telefono', e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    error={this.props.selectRowReferencia !== undefined ? this.props.selectRowReferencia.get('parentesco') ? false : true : null} margin="normal"
                                    id="parentesco" label="Parentesco" size="small" fullWidth
                                    value={this.props.selectRowReferencia === undefined || this.props.selectRowReferencia === null ? '' : this.props.selectRowReferencia.get('parentesco')}
                                    onChange={(e) => this.props.changeAttr("REFERENCIA", 'parentesco', e.target.value)}
                                />
                            </div>
                        </div>
                    </Modal>
                )
            default:
                return null;
        }
    }

    hideMainMenu() {
        this.setState({ showMainMenu: !this.state.showMainMenu })
    }


    render() {
        const { ids, list, rutas, cartera, cobrador, idRuta, user } = this.props;
        var today = moment((new Date())).format('YYYY-MM-DD');
        let buttons = []
        if (this.state._permisos.editar) {
            buttons = [
                <BoxButton key="br[0][0]" name="plus" onClick={() => this.createAction()} title="Agregar crédito" classCSS="info" disabled={idRuta != null ? false : true} />,
                <BoxButton key="br[0][1]" name="exchange-alt" onClick={() => this.actionToogleSidebarRigth1()} title="Enrutar" classCSS="info" disabled={idRuta != null ? false : true} />,
                <BoxButton key="br[0][2]" name="save" onClick={() => this.saveAbonos()} title="Guardar abonos" classCSS="info" disabled={idRuta != null ? false : true} />,
                // <BoxButton key="br[0][3]" name="filter" onClick={() => this.props.showHideModalFilter(true, tableColumnsRutas, 'rutas')} title="Filtrar información" classCSS="info" disabled={idRuta != null ? false : true} />,
                <BoxButton key="br[0][4]" name="file-pdf" onClick={() => this.onExportDataGrid(list, idRuta, cobrador)} title="Exportar ruta" classCSS="info" disabled={idRuta != null ? false : true} />,
            ]
        }

        if (!this.state._permisos.ver) {
            return (
                <Permisos ref="dataExport" />
            )
        } else {
            return (
                <div className="col-md-12">
                    {
                        !this.state.showMainMenu ?
                            null
                            :
                            <div className="sidenav-left" >
                                <div className="card" style={{ height: "100%" }}>
                                    <div className="card-header">
                                        <h3 className="card-title">Información de ruta</h3>
                                        <div className="card-options">
                                            <BoxButton key="bp[0][0]" name="times" onClick={() => this.hideMainMenu()} title="Cerrar" classCSS="info" />
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ paddingTop: 10, marginBottom: 0, background: '#f7f7f7', marginLeft: 0, marginRight: 0, paddingBottom: 0, height: '100%' }}>
                                        <div className="form-group">
                                            <FormControl fullWidth size="small">
                                                <InputLabel htmlFor="obsDia">Ruta</InputLabel>
                                                <NativeSelect
                                                    value={this.props.idRuta ? this.props.idRuta : 0}
                                                    onChange={(e) => this.onChangeSelect(e.target.value)}
                                                    inputProps={{
                                                        name: 'obsDia',
                                                        id: 'obsDia',
                                                    }}
                                                >
                                                    <option value="0">Seleccione...</option>
                                                    {
                                                        rutas.map((x) => {
                                                            return (
                                                                <option value={x.get('value')} key={x.get('value')} >{x.get('label')}</option>
                                                            )
                                                        })
                                                    }
                                                </NativeSelect>
                                            </FormControl>
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                type="date" id="Fecha" label="Fecha" size="small" fullWidth
                                                value={today} readOnly disabled
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                id="Cartera" label="Cartera" size="small" fullWidth
                                                value={numeral(cartera).format('')} readOnly disabled
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                id="Cobrador" label="Cobrador" size="small" fullWidth
                                                value={cobrador !== 'Sin asignar' ? cobrador.nombres + ' ' + cobrador.apellidos : cobrador} readOnly disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }

                    {
                        this.props.toogleSidebarRigth1 ?
                            <div className="card border-left-success">
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className={`m-0 ${CLIENTE(1)}`}>Enrutar clientes</h6>
                                    <div className="float-right">
                                        <BoxButton key="bp[0][0]" name="times" onClick={() => this.actionToogleSidebarRigth1()} title="Cerrar" classCSS="info" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-xs-12" style={{ padding: 0 }} >
                                    <div style={{ height: "calc(100vh - 248px)" }} ref="dataExport1">
                                        <Enrutar
                                            height={this.state.gridHeight + 19}
                                            ids={ids}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="card shadow border-left-success mb-4">
                                <div className={`card-status card-status-left ${CLIENTE()}`}></div>
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <IconButton size="large" className={`${CLIENTE(1)}`}>
                                        <SettingsIcon onClick={() => this.hideMainMenu()} />
                                    </IconButton>
                                    <h5 className={`m-0 ${CLIENTE(1)}`}>Planilla de ruta {idRuta} </h5>
                                    <div className="card-options">
                                        {
                                            buttons.map(item => {
                                                return (item)
                                            })
                                        }
                                    </div>
                                </div>
                                <div style={{ marginLeft: 2, height: "calc(100vh - 188px)", maxHeight: "calc(100vh - 188px)" }} ref="dataExport">
                                    <DataGrid
                                        height={this.state.gridHeight}
                                        rows={list}
                                        ids={ids}
                                        changeAction={this.changeAction}
                                        actionClick={this.actionClick}
                                        actionClickRenovados={this.actionClickRenovados}
                                        actionClickDias={this.actionClickDias}
                                        actionClickCancelarRenovado={this.actionClickCancelarRenovado}
                                        actionClickRenovadosInmediatos={this.actionClickRenovadosInmediatos}
                                        actionClickEliminarCredito={this.actionClickEliminarCredito}
                                        user={user}
                                    />
                                </div>
                                {
                                    this.state.toogleSidebarRigth ?
                                        <div className="sidenav-fondo">
                                            <div className="sidenav">
                                                <div className="row">
                                                    <div className="col-md-9" style={{ paddingLeft: 25 }}>
                                                        <h5>Agregar nuevo cliente:</h5>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="col">
                                                            <div className="float-right">
                                                                <BoxButton key="bp[0][0]" name="times" onClick={() => this.actionToogleSidebarRigth(0)} title="Cerrar" classCSS="info" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <AddClientes toggleReferencia={this.toggleReferencia} showReferencias={false} saveAction={this.saveAction} user={this.state._permisos} />
                                            </div>
                                        </div>
                                        : null
                                }
                                {this.renderSwitch(this.state.tipoModal)}
                                <ModalFilterMaestras />
                            </div>
                    }
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        list: state.rutas.get('list'),
        ids: state.rutas.get('ids'),
        rutas: state.rutas.get('rutas'),
        cartera: state.rutas.get('cartera'),
        nuevos: state.rutas.get('nuevos'),
        idRuta: state.rutas.get('idRuta'),
        obsDias: state.rutas.get('obsDias'),
        user: state.auth.user,
        cobrador: state.rutas.get('cobrador'),
        rows: state.rutas.get('rows'),
        toogleSidebarRigth1: state.common.get('toogleSidebarRigth1'),
        selectRowReferencia: state.clientes.get('selectRowReferencia'),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListRutas: () => dispatch(getListRutas()),
        getListPeriodos: () => dispatch(getListPeriodos()),
        getCreditos: (id) => dispatch(getCreditos(id)),
        selectAction: (id, reloadGrid, tipo) => dispatch(selectAction(id, reloadGrid, tipo)),
        changeAttr2: (tipo, id, attr, value) => dispatch(changeAttr2(tipo, id, attr, value)),
        toggleModal: () => dispatch(toggleModal()),
        newRow: (tipo) => dispatch(newRow(tipo)),
        saveCredito: () => dispatch(saveCredito()),
        saveAbonos: (entrada, salida, utilidad, coteos, moras) => dispatch(saveAbonos(entrada, salida, utilidad, coteos, moras)),
        saveRenovacion: (id) => dispatch(saveRenovacion(id)),
        saveRenovacion1: (id) => dispatch(saveRenovacion1(id)),
        saveRenovacionInmediata: (id) => dispatch(saveRenovacionInmediata(id)),
        deleteRenovacion: (id) => dispatch(deleteRenovacion(id)),
        deleteCredito: (id) => dispatch(deleteCredito(id)),
        cleanCliente: () => dispatch(cleanCliente()),
        cleanDataRutas: () => dispatch(cleanDataRutas()),
        reorderData: () => dispatch(reorderData()),
        toogleSidebar1: () => dispatch(toogleSidebar1()),
        saveAction: () => dispatch(saveAction()),
        clearNewClientesCreditos: () => dispatch(clearNewClientesCreditos()),
        showHideModalFilter: (state, columnas, mode) => dispatch(showHideModalFilter(state, columnas, mode)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rutas)