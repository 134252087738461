import * as types from '../actions/types'
import Immutable from 'immutable'
import moment from 'moment'

const INITIAL_STATE = Immutable.fromJS({
    clientesNew: [],
    cantNew: 0,
    cantRen: 0,
    label: [],
})

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_DASHBOARD:
            const days = [];
            const nuevos = [];
            const renovados = [];

            var daysInMonth = moment().daysInMonth();
            while (daysInMonth) {
                var current = moment().date(daysInMonth).format("MM-DD");
                days.push(current);
                daysInMonth--;
            }

            // eslint-disable-next-line array-callback-return
            days.reverse().map((item) => {
                let data = action.payload.label1.findIndex((element) => String(element) === String(item));
                if (data > -1)
                    nuevos.push(action.payload.data_N[data]);
                else
                    nuevos.push(0);

                data = action.payload.label2.findIndex((element) => String(element) === String(item));
                if (data > -1)
                    renovados.push(action.payload.data_R[data]);
                else
                    renovados.push(0);
            })

            state = state.set('cantNew', action.payload.cantNew)
            state = state.set('cantRen', action.payload.cantRen)
            state = state.set('label', days)
            state = state.set('data_N', nuevos)
            state = state.set('data_R', renovados)
            return state

        default:
            return state
    }
}