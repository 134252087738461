import React, { Component } from 'react';
import { connect } from 'react-redux'

// UI
import TableVirtualized from '../../Common/TableVirtualized'
import BoxButtonV2 from '../../Common/BoxButtonV2'
import ModalFilterMaestras from '../../Common/ModalFilterMaestras'

import { CLIENTE } from '../../../actions/index'
import { getClientes } from '../../../actions/clientes'
import { selectAction, toggleModal } from '../../../actions/common'
import { tableColumnsClientes } from '../../../utils/headersColumns'
import { showHideModalFilter } from "../../../actions/filtrarData";

class GridClientes extends Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        // this.actionGetDetails = this.actionGetDetails.bind(this);
    }

    componentWillMount() {
        this.props.getClientes();
    }

    // actionGetDetails(id) {
    //     if (id != undefined) {
    //         this.props.getDetallesClientes(id);
    //         this.props.toggleModal();
    //     }
    // }

    render() {
        const { ids, list, selected, actionNewRow, selectAction, creditosActivos, list1, user, actionGetDetails } = this.props;
        const tipo = "CLIENTE";

        let buttons = [];
        if (user.editar) {
            buttons = [
                <BoxButtonV2 key="bb[0][0]" name="plus" onClick={() => actionNewRow(tipo)} title="Agregar cliente" classCSS="info" />,
                <BoxButtonV2 key="bb[0][1]" name="filter" onClick={() => this.props.showHideModalFilter(true, tableColumnsClientes, 'clientes')} title="Filtrar información" classCSS="info" />,
                <BoxButtonV2 key="bb[0][2]" name="redo-alt" onClick={() => this.props.getClientes()} title="Refrescar" classCSS="info" />,
            ]
        }

        return (
            <div className="card shadow border-left-success mb-4">
                <div className={`card-status card-status-left ${CLIENTE()}`}></div>
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <div className="row col-lg-11">
                        <div className="col-lg-4">
                            <h5 className={`m-0 ${CLIENTE(1)}`}>Gestión de clientes</h5>
                        </div>
                        <div className="col-lg-7" style={{ display: 'flex' }}>
                            <div style={{ marginRight: 100 }}>
                                Total <span className={`badge ${CLIENTE()}`}>{list1 ? list1.size : 0}</span>
                            </div>
                            <div>
                                Con credito activo <span className={`badge ${CLIENTE()}`}>{creditosActivos}</span>
                            </div>
                        </div>

                    </div>
                    <div className="card-options">
                        {
                            buttons.map(item => {
                                return (item)
                            })
                        }
                    </div>
                </div>
                <div className="" style={{ paddingLeft: 2, height: 'calc(100vh - 187px)' }} ref="AutoSizer">
                    <TableVirtualized
                        tableColumns={tableColumnsClientes}
                        ids={ids}
                        list={list}
                        keyVal="id"
                        actionSelect={selectAction}
                        selected={selected}
                        tipo={tipo}
                        actionClick={this.props.actionEditCliente}
                        actionClick1={this.props.actionEstadoCliente}
                        actionDoubleClick={() => actionGetDetails(selected)}
                    />
                </div>
                <ModalFilterMaestras />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        list: state.clientes.get('list'),
        ids: state.clientes.get('ids'),
        selected: state.clientes.get('selected'),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getClientes: () => dispatch(getClientes()),
        toggleModal: () => dispatch(toggleModal()),
        selectAction: (id, reloadGrid, tipo) => dispatch(selectAction(id, reloadGrid, tipo)),
        showHideModalFilter: (state, columnas, mode) => dispatch(showHideModalFilter(state, columnas, mode)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GridClientes)