import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DenseTable from './DenseTable'
import Swal from 'sweetalert2'

//UI
import { getClientes, selectCliente, addListClientes, removeCliente } from '../../../actions/rutas'

import { changeAttr } from '../../../actions/common'
import { CLIENTE } from '../../../actions/index'

class AddCredito extends Component {

    componentDidMount() {
        this.props.getClientes();
    }

    onClickAddRow() {
        var row = this.props.selectRow.toJS();
        var dato = "";
        if (row.cliente == null)
            dato = "EL CLIENTE NO PUEDE IR VACIO"
        else if (row.prestamo == "")
            dato = "EL VALOR A PRESTAR NO PUEDE IR VACIO"
        else if (row.cuota == "")
            dato = "LA CUOTA NO PUEDE IR VACIA"
        else if (row.dias == "")
            dato = "LOS DÍAS NO PUEDE IR VACIA"

        if (dato == "") {
            this.props.addListClientes()
            this.refs[`cliente`].getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: dato,
            })
        }
    }

    render() {
        const { selectRow, changeAttr, periodos, clientes, rows } = this.props;
        const tipo = "RUTA";
        return (
            <div className="row">
                <div className="col-8">
                    <div className="row">
                        <div className="col-md-5">
                            <Autocomplete
                                id="cliente"
                                ref={"cliente"}
                                size="small"
                                options={clientes}
                                selectOnFocus
                                clearOnBlur
                                // value={(option) => option != null ? option.titular : null}
                                getOptionLabel={(option) => option != null ? option.titular : ""}
                                renderOption={(option) => (
                                    <Typography style={{ fontSize: "12px" }}>
                                        {option.cc_titular} - {option.titular}
                                    </Typography>
                                )}
                                renderInput={(params) => <TextField {...params} label="Cliente" />}
                                onChange={(event, option) => option ? this.props.selectCliente(option.id) : null}
                            />
                        </div>
                        <div className="col-md-3">
                            <button type="button" className={`btn btn-icon ${CLIENTE()}`} onClick={() => this.props.action()} style={{ marginTop: 18 }}  >
                                <i className="fas fa-male"></i> Cliente nuevo
                            </button>
                        </div>
                        <div className="col-md-4">
                            <FormControl fullWidth size="small">
                                <InputLabel htmlFor="obsDia">Días</InputLabel>
                                <Select
                                    value={selectRow.get('obsDia')}
                                    inputProps={{
                                        name: 'obsDia',
                                        id: 'obsDia',
                                    }}
                                    onChange={(e) => changeAttr(tipo, 'obs_dia', e.target.value)}
                                >
                                    <option value="" key={0}>Seleccione..</option>
                                    {
                                        this.props.obsDias.map((x, i) => {
                                            return (
                                                <option value={x.get("label")} key={i}>{x.get("label")}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <TextField id="fecha" label="Fecha del crédito" size="small" fullWidth type="date" margin="normal" value={selectRow !== null ? moment(selectRow.get('fecha')).format('YYYY-MM-DD') : ''} onChange={(e) => changeAttr(tipo, 'fecha', e.target.value)} />
                        </div>
                        <div className="col-md-4">
                            <TextField id="prestamo" label="Valor a prestar" size="small" fullWidth type="number" margin="normal" value={selectRow.get('prestamo')} onChange={(e) => changeAttr(tipo, 'prestamo', e.target.value)} />
                        </div>
                        <div className="col-md-4">
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="modalidad">Modalidad</InputLabel>
                                <Select
                                    value={selectRow.get('modalidad')}
                                    inputProps={{
                                        name: 'modalidad',
                                        id: 'modalidad',
                                    }}
                                    onChange={(e) => changeAttr(tipo, 'modalidad', e.target.value)}
                                >
                                    <option value="" key={0}>Seleccione..</option>
                                    {
                                        periodos.map((x) => {
                                            return (
                                                <option value={x.get("value")} key={x.get("label")} >{x.get("label")}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <TextField id="cuota" label="Cuota" size="small" fullWidth type="number" margin="normal" value={selectRow.get('cuota')} onChange={(e) => changeAttr(tipo, 'cuota', e.target.value)} />
                        </div>
                        <div className="col-md-4">
                            <TextField id="dias" label="Días" size="small" fullWidth type="number" margin="normal" value={selectRow.get('dias')} onChange={(e) => changeAttr(tipo, 'dias', e.target.value)} />
                        </div>
                        <div className="col-md-4">
                            <TextField id="cuota" label="Valor total" size="small" fullWidth type="number" margin="normal" value={(selectRow.get('cuota') * selectRow.get('dias')) * 1000} readOnly disabled />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <TextField id="observaciones" label="Observaciones" size="small" multiline fullWidth rowsMax={4} margin="normal" value={selectRow.get('observaciones')} onChange={(e) => changeAttr(tipo, 'observaciones', e.target.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            <Button color="primary" className={`${CLIENTE(1)}`} onClick={() => this.onClickAddRow()} startIcon={<PersonAddIcon />}>
                                Agregar cliente
                            </Button>
                        </div >
                    </div>
                </div>
                <div className="col-4">
                    <DenseTable rows={rows} action={this.props.removeCliente} />
                </div>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        selectRow: state.rutas.get('selectRow'),
        periodos: state.rutas.get('periodos'),
        clientes: state.rutas.get('clientes'),
        rows: state.rutas.get('rows'),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeAttr: (tipo, attr, value) => dispatch(changeAttr(tipo, attr, value)),
        getClientes: () => dispatch(getClientes()),
        addListClientes: () => dispatch(addListClientes()),
        selectCliente: (id) => dispatch(selectCliente(id)),
        removeCliente: (index) => dispatch(removeCliente(index)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCredito)